import fetchWrapper from './fetchWrapper'
import configs from './configs'

const dataUriToBlob = (dataUri) => {
  const splitDataURI = dataUri.split(',')
  const byteString =
    splitDataURI[0].indexOf('base64') >= 0
      ? atob(splitDataURI[1])
      : decodeURI(splitDataURI[1])
  const mimeString = splitDataURI[0].split(':')[1].split(';')[0]
  const ia = new Uint8Array(byteString.length)
  for (let i = 0; i < byteString.length; i += 1)
    ia[i] = byteString.charCodeAt(i)

  return new Blob([ia], { type: mimeString })
}

class BackendApis {
  async attemptLogin(sellerName, password) {
    try {
      const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ sellerName, password }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/login`,
        options,
      )
      const data = await res.json()
      return data?.data
    } catch (err) {
      console.log(err)
    }
  }

  async adminLogin(sellerName, adminAccountId, password) {
    try {
      const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ sellerName, adminAccountId, password }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/admin/login`,
        options,
      )
      const data = await res.json()
      return data?.data
    } catch (err) {
      console.log(err)
    }
  }

  async uploadSellerNoticeImage(mainImagesData) {
    try {
      const uploadImage = new FormData()
      mainImagesData.forEach((image, index) => {
        uploadImage.append(
          'image',
          dataUriToBlob(image.sourceUri),
          `image_${index}.${image.sourceUri.split(';')[0].split('/')[1]}`,
        )
      })
      const options = {
        method: 'PUT',
        body: uploadImage,
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/notice/image`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async giveUserCoupon(userId, couponInfo, expiresAt, message) {
    try {
      const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ userId, couponInfo, expiresAt, message }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/coupons`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async loadSellerBusinessRegistrationInfo() {
    try {
      const options = { method: 'GET' }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/business-registration-info-total`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async loadSellerInfo() {
    try {
      const options = { method: 'GET' }
      const res = await fetchWrapper(`${configs.backendUrl}/sellers`, options)
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getItemCategoryInfoByQuery(query) {
    try {
      const options = { method: 'GET' }
      const res = await fetchWrapper(
        `${configs.backendUrl}/items/item-category/query?q=${query}`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getCategoryInfosByIds(ids) {
    try {
      const options = { method: 'GET' }
      const res = await fetchWrapper(
        `${configs.backendUrl}/items/item-category/ids?ids=${ids}`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getCategoryInfoById(id) {
    try {
      const options = { method: 'GET' }
      const res = await fetchWrapper(
        `${configs.backendUrl}/items/item-category/id?id=${id}`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getLargeCategoryInfos() {
    try {
      const options = { method: 'GET' }
      const res = await fetchWrapper(
        `${configs.backendUrl}/items/item-category/large`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async checkNoticeImageUpload() {
    try {
      const options = { method: 'GET' }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/check/notice/image`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async checkWinnerOneClickSeller() {
    try {
      const options = { method: 'GET' }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/check/winner-one-click-seller`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async checkDealOneClickSeller() {
    try {
      const options = { method: 'GET' }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/check/deal-one-click-seller`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async checkDealStandSeller(dealType) {
    try {
      const options = { method: 'GET' }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/check/deal-stand?dealType=${dealType}`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async handleDealConfirm(dealItemInfoArray, isConfirmed = true) {
    try {
      const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ dealItemInfoArray, isConfirmed }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/deal-stand/confirm`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getPenaltyPointRecent() {
    try {
      const options = { method: 'GET' }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/penalty/point/recent`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getRecentTodayDeparturePenalty() {
    try {
      const options = { method: 'GET' }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/penalty/point/todayDeparture`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async loadSellerDueSettlements() {
    try {
      const options = { method: 'GET' }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/settlements/dues`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getCrawlQueue() {
    try {
      const options = { method: 'GET' }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/crawl-queue/get/url`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async registerCrawlQueue(storeUrl) {
    try {
      const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ storeUrl }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/crawl-queue/register`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async turnCrawlQueueStatus(queueId, needCrawl) {
    try {
      const options = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ queueId, needCrawl }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/crawl-queue/update`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async deleteCrawlQueue(queueId) {
    try {
      const options = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ queueId }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/crawl-queue/delete`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getTotalLengthofCheapabilityNudgeItems(condition) {
    try {
      const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ condition }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/cheapability-nudge/items/total-length`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getCheapabilityNudgeItems(condition, loadNum, chunkSize) {
    try {
      const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ condition, loadNum, chunkSize }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/cheapability-nudge/items`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getSellerExternalItemData(condition, loadNum, chunkSize) {
    try {
      const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ condition, loadNum, chunkSize }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/external-item-data`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getSellerExternalItemDataTotalLength(condition) {
    try {
      const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ condition }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/external-item-data/total-length`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getSellerExternalWinnerDataTotalLength(condition) {
    try {
      const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ condition }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/winner-item-data/total-length`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getSellerDealOneClickTotalLength(condition) {
    try {
      const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ condition }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/deal-oneclick-item-data/total-length`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getSellerWinnerItemData(condition, loadNum, chunkSize) {
    try {
      const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ condition, loadNum, chunkSize }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/winner-item-data`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getSellerDealOneClickItemData(condition, loadNum, chunkSize) {
    try {
      const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ condition, loadNum, chunkSize }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/deal-oneclick-item-data`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async oneClickRegisterAllExternalItems(
    chunkSize,
    salesRatio = 10,
    soldOut = false,
  ) {
    try {
      const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ chunkSize, salesRatio, soldOut }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/items/external-item-data/one-click-register-all`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async oneClickRegisterWinnerItems(
    chunkSize,
    salesRatio = 10,
    soldOut = false,
    selectedCatalogIds = [],
    shippingInfo = {},
  ) {
    try {
      const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          chunkSize,
          salesRatio,
          soldOut,
          selectedCatalogIds,
          shippingInfo,
        }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/items/external-item-data/one-click-register-winner`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async oneClickRegisterDealItems(
    chunkSize,
    salesRatio = 10,
    soldOut = false,
    selectedCatalogIds = [],
    shippingInfo = {},
  ) {
    try {
      const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          chunkSize,
          salesRatio,
          soldOut,
          selectedCatalogIds,
          shippingInfo,
        }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/items/external-item-data/one-click-register-deal`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async loadSegmentedSellerDueSettlements(payedAt) {
    try {
      const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ payedAt }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/settlements/dues/info-request`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async loadSegmentedRevenuesBeforeSettlements(payedAt) {
    try {
      const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ payedAt }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/revenues/pre-settlements/info-request`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async loadRevenuesBeforeSettlemnts() {
    try {
      const options = { method: 'GET' }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/revenues/pre-settlements`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async loadSellerPastSettlementOrders(settlementId, numLoadedOrders) {
    try {
      const options = { method: 'GET' }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/settlements/orders?settlementId=${settlementId}&numLoadedOrders=${numLoadedOrders}`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async loadSellerPastSettlements() {
    try {
      const options = { method: 'GET' }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/settlements`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getOrdersForTaxInvoice(startDate, endDate) {
    try {
      const url = `${configs.backendUrl}/sellers/orders-for-tax-invoice?startDate=${startDate}&endDate=${endDate}`
      const res = await fetchWrapper(url, { method: 'GET' })
      const data = await res.json()
      return data
    } catch (error) {
      console.log(error)
    }
  }

  async loadSellerTaxInvoice(startDate, endDate) {
    try {
      const url = `${configs.backendUrl}/sellers/tax-invoices?startDate=${startDate}&endDate=${endDate}`
      const res = await fetchWrapper(url, { method: 'GET' })
      const data = await res.json()
      return data
    } catch (error) {
      console.log(error)
    }
  }

  async checkSellerWithPlatformUsageFee(startDate, endDate) {
    try {
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/platform-usage-fee?startDate=${startDate}&endDate=${endDate}`,
        { method: 'GET' },
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async loadSellerTaxInvoiceOrders(taxInvoiceRawId, numLoadedOrders) {
    try {
      const url = `${configs.backendUrl}/sellers/raw-tax-invoices/orders?taxInvoiceRawId=${taxInvoiceRawId}&numLoadedOrders=${numLoadedOrders}`
      const res = await fetchWrapper(url, { method: 'GET' })
      const data = await res.json()
      return data
    } catch (error) {
      console.log(error)
    }
  }

  async loadSellerTaxInvoiceSettlementExcepts(
    taxInvoiceRawId,
    numLoadedSettlementExcepts,
  ) {
    try {
      const url = `${configs.backendUrl}/sellers/raw-tax-invoices/settlement-excepts?taxInvoiceRawId=${taxInvoiceRawId}&numLoadedSettlementExcepts=${numLoadedSettlementExcepts}`
      const res = await fetchWrapper(url, { method: 'GET' })
      const data = await res.json()
      return data
    } catch (error) {
      console.log(error)
    }
  }

  async loadSellerTaxInvoiceRawByDate(startDate, endDate) {
    try {
      const url = `${configs.backendUrl}/sellers/raw-tax-invoices?startDate=${startDate}&endDate=${endDate}`
      const res = await fetchWrapper(url, { method: 'GET' })
      const data = await res.json()
      return data
    } catch (error) {
      console.log(error)
    }
  }

  async requestVerificationNumber(phoneNumber, sellerName) {
    try {
      const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ phoneNumber, sellerName }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/verification-numbers`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async reportPasswordMissing(sellerName, phoneNumber) {
    try {
      const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ sellerName, phoneNumber }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/passwords/missing`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async deleteSellerNoticeImage() {
    try {
      const options = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/notice/delete`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async changePassword(password) {
    try {
      const options = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ password }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/passwords`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async loadOrders() {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/orders`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getOrderStatusesByIds(orderIds) {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/orders/statuses?ids=${orderIds}`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async loadOrdersByOrderIds(orderIds) {
    try {
      const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ orderIds }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/orders/orders-by-order-ids`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async loadSellerCalculatedScores() {
    try {
      const options = {
        method: 'GET',
        // cache: 'no-cache',
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/points/calculated`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async loadSellerPeriodCalculatedScores() {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/score/period-calculated`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async loadSellerMaxWeeklyTotalOrdersLastMonth() {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/score/max-weekly-total-orders-last-month`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async loadSellerRecentShippingDurations() {
    try {
      const options = {
        method: 'GET',
        // cache: 'no-cache',
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/points/shippings`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async setSellerPreShippingOrdersExcelDownloadedAt(orderIds) {
    try {
      const options = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ orderIds }),
        cache: 'no-cache',
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/orders/pre-shipping`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async updateShippingInfoInBulkByExcel(modifiedShippingInfoList) {
    try {
      const options = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ modifiedShippingInfoList }),
        cache: 'no-cache',
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/modification/shipping-info-from-excel/bulk`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async updateItemsInfoInBulkByExcel(modifiedItemsInfoList) {
    try {
      const options = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ modifiedItemsInfoList }),
        cache: 'no-cache',
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/modification/items-info-from-excel/bulk`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async updateTimeDealItemsInfoInBulkByExcel(modifiedItemsInfoList) {
    try {
      const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ modifiedItemsInfoList }),
        cache: 'no-cache',
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/modification/time-deal-items-info-from-excel/bulk`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getPreShippingPreExcelItems() {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/items/pre-shipping/pre-excel`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async checkSellerPreShippingOrders(orderIds) {
    try {
      const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ orderIds }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/orders/pre-shipping/validity`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getOrdersInStatusBeforeCertainTimeStamp(status, payedAt, preExcel) {
    try {
      const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ status, payedAt, preExcel }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/orders/status/info-request`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getPreExcelOrdersInStatusBeforeCertainTimeStamp(
    status,
    payedAt,
    itemIds,
  ) {
    try {
      const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ status, payedAt, itemIds }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/orders/status/pre-excel/info-request`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getPostExcelOrdersInStatusBeforeCertainTimeStamp(status, payedAt) {
    try {
      const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ status, payedAt }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/orders/status/post-excel/info-request`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getSegmentedOrdersInStatus(status, payedAt, preExcel, itemIds) {
    try {
      const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ status, payedAt, preExcel, itemIds }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/orders/status/segmented/info-request`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getRecentOrdersPerStatus() {
    try {
      const options = {
        method: 'GET',
        cache: 'no-cache',
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/orders/status`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getSmallCategoryTopItems(smallCategoryId) {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/small-category-items?smallCategoryId=${smallCategoryId}`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getMaxSmallCategoryId() {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/max-small-category-id`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getMinimumCategoryInfo() {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/minimumCategoryInfo`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async loadCompensationCouponRegisteredOrders() {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/orders/fast-arrival-coupon-orders`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async loadProgressingFastArrivalOrders() {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/orders/total-progressing-fast-arrival-orders`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async loadFastArrivalOrdersByStatus(status) {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/orders/total-fast-arrival-orders-by-status?status=${status}`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async loadSegmentedFastArrivalArrivedOrders(startDate, endDate) {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/orders/segmented-arrived-fast-arrival-orders?startDate=${startDate}&endDate=${endDate}`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async loadArrivedFastArrivalOrders() {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/orders/total-arrived-fast-arrival-orders`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getUserOrders(userId) {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/orders/users?userId=${userId}`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async setSellerImage(sellerId, imageData) {
    try {
      const uploadImage = new FormData()
      uploadImage.append('sellerId', sellerId)
      imageData.forEach((image, index) => {
        uploadImage.append(
          'image',
          dataUriToBlob(image.sourceUri),
          `image_${index}.${image.sourceUri.split(';')[0].split('/')[1]}`,
        )
      })
      const options = { method: 'POST', body: uploadImage }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/profiles/images`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async loadQuestionsFromAdmin(createdAt) {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${
          configs.backendUrl
        }/sellers/questions-from-admin?createdAt=${encodeURIComponent(
          createdAt,
        )}`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async loadAnsweredQuestionsFromAdmin() {
    const options = {
      method: 'GET',
    }
    const res = await fetchWrapper(
      `${configs.backendUrl}/sellers/questions-from-admin/answered`,
      options,
    )
    const data = await res.json()
    return data
  }

  async loadQuestionsByCondition(condition, limit, skip) {
    const options = {
      method: 'GET',
    }
    const res = await fetchWrapper(
      `${
        configs.backendUrl
      }/sellers/questions-by-condition?condition=${encodeURIComponent(
        JSON.stringify(condition),
      )}&limit=${limit}&skip=${skip}`,
      options,
    )
    const data = await res.json()
    return data
  }

  async loadItemWinnerItemsByCondition(condition, limit, skip) {
    const options = {
      method: 'GET',
    }
    const res = await fetchWrapper(
      `${
        configs.backendUrl
      }/items/search-item-winner-condition?condition=${encodeURIComponent(
        JSON.stringify(condition),
      )}&limit=${limit}&skip=${skip}`,
      options,
    )
    const data = await res.json()
    return data
  }

  async loadQuestionBundleByOrderId(questionType, orderId) {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/question-bundle-by-orderId?orderId=${orderId}&questionType=${questionType}`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  /**
   * @description - 상품 질문에 대한 답변을 수정합니다.
   * @param {Object} params
   * @param {string} params.questionId
   * @param {string} params.text - 수정할 답변
   * @returns {Promise<{status:number, data:{success:boolean, msg: string}}>}
   */
  async modifyItemQuestion({ questionId, text, type }) {
    try {
      const options = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ questionId, text, type }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/item-questions/answers`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async answerItemQuestion(questionId, text) {
    try {
      const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ questionId, text }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/item-questions/answers`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async addReviewReply(reviewId, text) {
    try {
      const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ reviewId, text }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/item-review/reply`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async deleteReviewReply(reviewId) {
    try {
      const options = {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          reviewId,
        }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/delete-review-reply`,
        options,
      )
      const data = res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async answerQuestionFromAdmin(questionId, text) {
    try {
      const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ questionId, text }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/questions-from-admin/answers`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async loadSellerItems(itemIds) {
    try {
      const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ itemIds }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/items/info-request`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async loadItemSpecificInfo(itemIds, projectCondition = { itemTitle: 1 }) {
    try {
      const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ itemIds, projectCondition }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/items/specific`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getItemsInfoForOwnerValidation(itemIds) {
    try {
      const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ itemIds }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/items/v2/list-request-for-owner-validation`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getSellerSegementedItemsInfoByCondition(condition, page, pageLimit) {
    try {
      const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ condition, page, pageLimit }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/items/v2/list-request`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getItemsInfoForExcelByCondition(condition) {
    try {
      const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ condition }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/items/excel/list-request`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getValidItemIds() {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/valid-item-ids`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getSellerItemsCountByCondition(condition) {
    try {
      const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ condition }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/items/v2/count-request`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getSellerFastArrivalItemsCountByCondition(condition) {
    try {
      const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ condition }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/items/v2/fast-arrival-count-request`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getFastArrivalItemsIds(condition) {
    try {
      const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ condition }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/items/fast-arrival-item-ids`,
        options,
      )
      const result = await res.json()
      return result
    } catch (err) {
      console.log(err)
    }
  }

  async loadSellerDeals(itemIds, type) {
    try {
      const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ itemIds, type }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/deals/info-request`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getCurrentDate() {
    try {
      const options = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/users/daily-check-in/now`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async updateItemOptionsInfo(
    itemId,
    optionsInfo,
    teamPurchasePrice,
    individualPurchasePrice,
    discountRate,
  ) {
    try {
      const options = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          itemId,
          optionsInfo,
          teamPurchasePrice,
          individualPurchasePrice,
          discountRate,
        }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/items/options`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getSellerOrderGuarantees() {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/price-changes/guarantees`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getItemReviewInfo(loadedReviewsNum, batchSize, sortType, focusItemId) {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/item-reviews?loadedReviewsNum=${loadedReviewsNum}&batchSize=${batchSize}&sortType=${sortType}&focusItemId=${focusItemId}`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async searchReviewByOrderId(orderId) {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/item-review-by-order-id?orderId=${orderId}`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getTenMinDealItems(page, limit) {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/10min/items?page=${page}&limit=${limit}`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getDealStandItemBySellerId(dealType) {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/deal-stand-item-by-sellerId?dealType=${dealType}`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getTenMinDealItemsCount() {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/10min/items/count`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async loadHolidays() {
    try {
      const options = { method: 'GET' }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/holidays`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getHolidayDealItemsCount(condition) {
    try {
      const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ condition }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/holiday/deal/items/count`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getHolidayDealItems(page, limit, condition) {
    try {
      const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ page, limit, condition }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/holiday/deal/items`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getSellerConsecutiveOrderGuarantees(itemId) {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/price-changes/consecutives?itemId=${itemId}`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getSellerPriceChangeFirstLogForNewSystem(itemId) {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/price-changes/firstlogForNewSystem?itemId=${itemId}`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getSellerPriceChangeLastLogForNewSystem(itemId) {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/price-changes/lastlogForNewSystem?itemId=${itemId}`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async recordSellerItemPriceChange(
    itemId,
    oldOptionsInfo,
    newOptionsInfo,
    averageChangePercentage,
    oldRepresentingTeamPurchasePrice,
    newRepresentingTeamPurchasePrice,
    representingPriceChange,
    orderPumpingRate,
    weeklyOrdersNum,
    projectedWeeklyOrdersNum,
    isGuaranteed,
  ) {
    try {
      const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          itemId,
          oldOptionsInfo,
          newOptionsInfo,
          averageChangePercentage,
          oldRepresentingTeamPurchasePrice,
          newRepresentingTeamPurchasePrice,
          representingPriceChange,
          orderPumpingRate,
          weeklyOrdersNum,
          projectedWeeklyOrdersNum,
          isGuaranteed,
        }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/price-changes`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async recordEventLog(eventLocation, eventAction) {
    try {
      const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          eventLocation,
          eventAction,
        }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/event-log`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async registerLuredealCandidate(itemInfo) {
    try {
      const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          itemInfo,
        }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/deal/register-luredeal-candidate`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async loadItemDetails(itemId, type = '') {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/items?itemId=${itemId}&type=${type}`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getSingleItemInfo(itemId) {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/items/single-item?itemId=${itemId}`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async loadSpecialDeal(itemId) {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/special-deal?itemId=${itemId}`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async loadItemShippedOrders(itemId, startTimeStamp, endTimeStamp) {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/items/orders?itemId=${itemId}&startTimeStamp=${startTimeStamp}&endTimeStamp=${endTimeStamp}`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async sendBulkSms(phoneNumbers, message, title) {
    try {
      const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ phoneNumbers, message, title }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/sms/bulk`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async uploadItemInfo(itemInfo) {
    try {
      const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ itemInfo }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/items`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async registerAdCreditCardInfo(cardInfo) {
    try {
      const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ cardInfo }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/ad-credit/card/register`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async updateAutoChargeAmount(autoChargeAmount) {
    try {
      const options = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ autoChargeAmount }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/ad-credit/auto-charge/credit/update`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async updateAutoChargeMode(autoChargeMode) {
    try {
      const options = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ autoChargeMode }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/ad-credit/auto-charge/update`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getWordNLPSimAndAvgBiddingPrice(itemTitle, keyword) {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/nlp/word-similarity?itemTitle=${itemTitle}&keyword=${keyword}`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getRecommendedKeywords(itemTitle, smallCategoryId) {
    try {
      const options = {
        method: 'GET',
      }

      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/recommended-keywords/get?itemTitle=${itemTitle}&smallCategoryId=${smallCategoryId}`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getTrendKeywords() {
    try {
      const options = {
        method: 'GET',
      }

      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/trend-keywords`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async registerItemAd(itemInfo, adType, detailInfo, inputRoas) {
    try {
      const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ itemInfo, adType, detailInfo, inputRoas }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/ad/item-ad/register`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async registerAcmAd(acmAdSwitch) {
    try {
      const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ acmAdSwitch }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/ad/acm-ad/register`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async registerQuizAd(adUnit) {
    try {
      const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ adUnit }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/ad/quiz-ad/register`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async registerQuizAdV2(adUnit) {
    try {
      const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ adUnit }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/ad/quiz-ad/register/v2`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async registerQuizAdForFreeEvent(adUnit) {
    try {
      const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ adUnit }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/ad/quiz-ad/register/for-free-event`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async registerSellerStoreAd(adUnit) {
    try {
      const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ adUnit }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/ad/store-ad/register`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async registerAlfarmMinigameAd(adUnit) {
    try {
      const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ adUnit }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/ad/alfarm-minigame-ad/register`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async registerAlfarmMinigameAdForFreeEvent(adUnit) {
    try {
      const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ adUnit }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/ad/alfarm-minigame-ad/register/for-free-event`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getFreeAdAvailableCheck() {
    try {
      const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/ad/alfarm-ad/get-free-ad-available-check`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async registerAlfarmLeavesAd(adUnit) {
    try {
      const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ adUnit }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/ad/alfarm-leaves-ad/register`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async registerAlfarmLeavesAdForFreeEvent(adUnit) {
    try {
      const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ adUnit }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/ad/alfarm-leaves-ad/register/for-free-event`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async registerAlfarmWinnerDealAd(adUnit) {
    try {
      const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ adUnit }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/ad/alfarm-winner-deal-ad/register`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async registerAlfarmWinnerDealAdForFreeEvent(adUnit) {
    try {
      const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ adUnit }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/ad/alfarm-winner-deal-ad/register/for-free-event`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async registerTrendQuizAd(adUnit) {
    try {
      const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ adUnit }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/ad/trend-quiz-ad/register`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getAcmAdInfo() {
    try {
      const options = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/ad/acm-ad/get`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getQuizAd(date, diff) {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/ad/quiz-ad/get-by-date?date=${date}&diff=${diff}`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getQuizAdBetweenDate(convertedStartDate, convertedEndDate) {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/ad/quiz-ad/between-date?convertedStartDate=${convertedStartDate}&convertedEndDate=${convertedEndDate}`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getWinnerDealAdBetweenDate(convertedStartDate, convertedEndDate) {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/ad/winner-deal-ad/between-date?convertedStartDate=${convertedStartDate}&convertedEndDate=${convertedEndDate}`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getLeavesAdBetweenDate(convertedStartDate, convertedEndDate) {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/ad/leaves-ad/between-date?convertedStartDate=${convertedStartDate}&convertedEndDate=${convertedEndDate}`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getMinigameAdBetweenDate(convertedStartDate, convertedEndDate) {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/ad/minigame-ad/between-date?convertedStartDate=${convertedStartDate}&convertedEndDate=${convertedEndDate}`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getStoreAdBetweenDate(convertedStartDate, convertedEndDate) {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/ad/store-ad/between-date?convertedStartDate=${convertedStartDate}&convertedEndDate=${convertedEndDate}`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getEggBreakersAdBetweenDate(convertedStartDate, convertedEndDate) {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/ad/egg-breakers-ad/between-date?convertedStartDate=${convertedStartDate}&convertedEndDate=${convertedEndDate}`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getQuizAdOrderData(date, itemIds) {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/ad/quiz-ad/order?date=${date}&itemIds=${itemIds}`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getStoreAdOrderData(date, itemIds) {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/ad/store-ad/order?date=${date}&itemIds=${itemIds}`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async cancelQuizAd(adId) {
    try {
      const options = {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          adId,
        }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/ad/quiz-ad/delete`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async cancelAlfarmStoreAd(adId) {
    try {
      const options = {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          adId,
        }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/ad/store-ad/delete`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async cancelAlfarmMinigameAd(adId) {
    try {
      const options = {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          adId,
        }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/ad/alfarm-minigame-ad/cancel`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async cancelAlfarmLeavesAd(adId) {
    try {
      const options = {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          adId,
        }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/ad/alfarm-leaves-ad/cancel`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async cancelAlfarmWinnerDealAd(adId) {
    try {
      const options = {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          adId,
        }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/ad/alfarm-winner-deal-ad/cancel`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getQuizRevenue() {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/ad/quiz-ad/revenue/get`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getAlfarmStoreAdRevenue() {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/ad/store-ad/revenue`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getAlfarmMinigameAdRevenue() {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/ad/alfarm-minigame-ad/revenue`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getAlfarmLeavesAdRevenue() {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/ad/alfarm-leaves-ad/revenue`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getAlfarmWinnerDealAdRevenue() {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/ad/alfarm-winner-deal-ad/revenue`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getEggBreakRevenue() {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/ad/egg-ad/revenue`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getEggAdApplicationListForAWeek(date) {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/egg-ad/get-ad-application-list-for-a-week/date?date=${date}`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getWinnerDealAdApplicationListForAWeek(date) {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/winner-deal-ad/get-ad-application-list-for-a-week/date?date=${date}`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getLeavesAdApplicationListForAWeek(date) {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/leaves-ad/get-ad-application-list-for-a-week/date?date=${date}`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getQuizAdApplicationListForAWeek(date) {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/quiz-ad/get-ad-application-list-for-a-week/date?date=${date}`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getMinigameAdApplicationListForAWeek(date) {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/minigame-ad/get-ad-application-list-for-a-week/date?date=${date}`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getStoreAdApplicationListForAWeek(date) {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/store-ad/get-ad-application-list-for-a-week/date?date=${date}`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getQuizProgressInfo() {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/ad/quiz-ad/progress/get`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getItemAd(itemId, adType) {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/ad/item-ad/get?itemId=${itemId}&adType=${adType}`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async updateItemAd(adUnitId, detailInfo, inputRoas) {
    try {
      const options = {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          cache: 'no-cache',
        },
        body: JSON.stringify({ adUnitId, detailInfo, inputRoas }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/ad/item-ad/update`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async registerItemAdBatch(adItemsInfo, adType) {
    try {
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          cache: 'no-cache',
        },
        body: JSON.stringify({ adItemsInfo, adType }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/ad/item-ad/register/batch`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async registerTimeDealBulkApplication(dealItemsInfo) {
    try {
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          cache: 'no-cache',
        },
        body: JSON.stringify({ dealItemsInfo }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/items/register/bulk/time-deal`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async registerTenMinDealBulkApplication(dealItemsInfo) {
    try {
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          cache: 'no-cache',
        },
        body: JSON.stringify({
          dealItemsInfo,
        }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/items/register/bulk/ten-min-deal`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async registerHolidayDealBulkApplication(dealItemsInfo) {
    try {
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          cache: 'no-cache',
        },
        body: JSON.stringify({
          dealItemsInfo,
        }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/items/register/bulk/holiday-deal`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async deleteItemAd(adId, adType, itemId) {
    try {
      const options = {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          adId,
          adType,
          itemId,
        }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/ad/item-ad/delete`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async updateItemSingleField(itemId, fieldName, value) {
    try {
      const options = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ itemId, fieldName, value }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/items/single-field-modification`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async updateItemRankTest(itemInfo, testPrice) {
    try {
      const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ itemInfo, testPrice }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/items/rank-test`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async updateManyItemsRankTest(itemIds) {
    try {
      const options = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ itemIds }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/items/rank-test/update/many`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async recordSellerAutonomousPriceChange(
    itemId,
    oldOptionsInfo,
    newOptionsInfo,
    oldTeamPurchasePrice,
    newTeamPurchasePrice,
    totalScore,
    estimatedTotalScore,
    applicationScreenName,
  ) {
    try {
      const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          itemId,
          oldOptionsInfo,
          newOptionsInfo,
          oldTeamPurchasePrice,
          newTeamPurchasePrice,
          totalScore,
          estimatedTotalScore,
          applicationScreenName,
        }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/items/autonomous-price-change`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async uploadBiddingItemInfo(itemInfo) {
    try {
      const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ itemInfo }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/bidding-item`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async updateWelcomeModalOpenInfo(sellerType) {
    try {
      const options = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ sellerType }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/update-welcome-modal-open-info`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getSellerAdUnitOverwriteData({ page, chunkSize }) {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/ad/overwrite-data?page=${page}&chunkSize=${chunkSize}`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async checkAdUnitOverwrite() {
    try {
      const options = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/ad/overwrite-data/check`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async removeItemFromSeller(itemId) {
    try {
      const options = {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ itemId }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/items`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async removeManyItemsFromSeller(itemIdList) {
    try {
      const options = {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          itemIdList,
        }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/items/delete-many`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async uploadAgreeToTAndC() {
    try {
      const options = {
        method: 'POST',
        // headers: { 'Content-Type': 'application/json' },
        // body: JSON.stringify({ agreeToTAndC }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/TAndC`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async updateItemInfo(itemInfo) {
    try {
      const options = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ itemInfo }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/items`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async loadShippingCompanies() {
    try {
      const options = { method: 'GET' }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/shipping-companies`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async loadSellerGmvForRank(sellerId) {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/seller-rank?sellerId=${sellerId}`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async loadSellerRank(sellerId) {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/seller-rank-name?sellerId=${sellerId}`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async loadSellerScoreForRank(sellerId) {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/seller-rank-score?sellerId=${sellerId}`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async loadSellerTotalOrderForRank(sellerId) {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/seller-orderrank?sellerId=${sellerId}`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async loadOrderForGoodService() {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/seller-order-goodservice`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async sendSMStoSeller(phoneNumber) {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/send-sms-seller?phoneNumber=${phoneNumber}`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async loadWinCategoryData(sellerId) {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/win-category-data?sellerId=${sellerId}`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getSellerInfoToNudge(sellerName) {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/seller-info-nudge?sellerName=${sellerName}`,
        options,
      )
      const data = await res.json()
      return data?.data[0]?.phoneNumber
    } catch (err) {
      console.log(err)
    }
  }

  async sellerItemCheck(sellerNameList) {
    try {
      const options = {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          cache: 'no-cache',
        },
        body: JSON.stringify({ sellerNameList }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/seller-item-check`,
        options,
      )
      const data = await res.json()
      return data.data
    } catch (err) {
      console.log(err)
    }
  }

  async registerSeller(
    sellerName,
    password,
    representorName,
    email,
    phoneNumber,
    termsAgreed,
  ) {
    try {
      const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          sellerName,
          password,
          representorName,
          email,
          phoneNumber,
          termsAgreed,
          sellerDisplayName: '',
          description: '',
          contactNumber: '',
          countryCode: '82',
          address: '',
          mailOrderBusinessNumber: '',
          businessRegistrationNumber: '',
          // kakaoChannelUrl: '',
          defaultShippingCompanyId: '',
          bankAccountInfo: {},
          recommenderName: '',
          route: '',
        }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/autonomous`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  // 현식 셀러 추가 정보 입력
  async registerSellerDetail(
    sellerName,
    sellerDisplayName,
    description,
    phoneNumber,
    contactNumber,
    email,
    countryCode,
    address,
    mailOrderBusinessNumber,
    businessRegistrationNumber,
    // kakaoChannelUrl,
    defaultShippingCompanyId,
    bankAccountInfo,
    representorName,
    sellerType,
    shippingOrderCapa,
    sellerDistributionType,
    categoryId,
    urlLink,
    repName,
    corpName,
    businessRegistrationNumberImage,
  ) {
    try {
      const options = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          sellerName,
          sellerDisplayName,
          description,
          phoneNumber,
          contactNumber,
          email,
          countryCode,
          address,
          mailOrderBusinessNumber,
          businessRegistrationNumber,
          // kakaoChannelUrl,
          defaultShippingCompanyId,
          bankAccountInfo,
          representorName,
          sellerType,
          shippingOrderCapa,
          sellerDistributionType,
          categoryId,
          urlLink,
          repName,
          corpName,
          businessRegistrationNumberImage,
        }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/detail`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async updateBusinessRegistrationNumber(
    businessRegistrationNumber,
    businessStartDate,
    ceoName,
  ) {
    try {
      const options = {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          businessRegistrationNumber,
          businessStartDate,
          ceoName,
        }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/business-registration-number/update`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getAttributeGroupByCategoryId(categoryId) {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/attribute-group?categoryId=${categoryId}`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getAttributeUnits() {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/attribute-group/units`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async registerRecommenderInfo(
    recommenderName,
    recommenderRank,
    recommenderNumber,
  ) {
    try {
      const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          recommenderName,
          recommenderRank,
          recommenderNumber,
        }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/recommender`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  // 현식 셀러 추가 정보 입력 끝

  async loadBanksInfo() {
    try {
      const options = { method: 'GET' }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/banks/total`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async loadShippingFeesTemplate() {
    try {
      const options = { method: 'GET' }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/shipping-fees-template`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async loadCategories() {
    try {
      const options = { method: 'GET' }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/categories`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async insertWinCategoryApplyItem(
    sellerId,
    sellerName,
    itemTitle,
    itemId,
    mediumCategoryKR,
  ) {
    try {
      const options = {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          cache: 'no-cache',
        },
        body: JSON.stringify({
          sellerId,
          sellerName,
          itemTitle,
          itemId,
          mediumCategoryKR,
        }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/win-category-item`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async loadCommonMetaDataList() {
    try {
      const options = { method: 'GET' }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/meta-data/common`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async loadMediumCategoryMetaDataList(mediumCategoryId) {
    try {
      const options = { method: 'GET' }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/meta-data/mediumCategory?mediumCategoryId=${mediumCategoryId}`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async loadSmallCategoryMetaDataList(smallCategoryId) {
    try {
      const options = { method: 'GET' }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/meta-data/smallCategory?smallCategoryId=${smallCategoryId}`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async withdrawCancelingPreshippingOrder(shippingInfos) {
    try {
      const options = {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          shippingInfos,
        }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/canceling-preShipping-orders`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async deleteItemOption(itemOptions) {
    try {
      const options = {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          itemOptions,
        }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/canceling-promotion-item`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async withdrawCancelingOrder(
    orderId,
    userId,
    denyReason,
    additionalDenyNote,
  ) {
    try {
      const options = {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          orderId,
          userId,
          denyReason,
          additionalDenyNote,
        }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/canceling-orders`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async checkShippingNumberValidity(shippingCompanyCode, shippingNumber) {
    const options = { method: 'GET' }
    const res = await fetchWrapper(
      `${configs.backendUrl}/sellers/shipping-info/validity?shippingCompanyCode=${shippingCompanyCode}&shippingNumber=${shippingNumber}`,
      options,
    )
    const data = await res.json()
    return data
  }

  async searchUsersByPhoneNumber(phoneNumber) {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/search-results/users?query=${phoneNumber}`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async searchQuestionsFromAdminByOrderId(orderId) {
    try {
      const options = {
        method: 'GET',
        // headers: {
        //   'Content-Type': 'application/json',
        // },
        // body: JSON.stringify({
        //   orderId,
        // }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/questions/admin?orderId=${orderId}`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  // 현식 수령인 검색

  async searchOrdersByRecipientPhoneNumber(query, payedAt) {
    try {
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ query, payedAt }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/search-results/recipients/info-request`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async uploadRepresentCategory(urlLink, categoryId) {
    try {
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ urlLink, categoryId }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/representcategory`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async postOrderPostalShippingInfo(orderId, shippingInfo) {
    try {
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          cache: 'no-cache',
        },
        body: JSON.stringify({ orderId, shippingInfo }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/shipping-info/postal`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  // Yechan 직접 배송 구현
  async postOrderDirectShippingInfo(orderId, shippingInfo) {
    try {
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          cache: 'no-cache',
        },
        body: JSON.stringify({ orderId, shippingInfo }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/shipping-info/direct`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  // Yechan 해당 주문의 status를 'arrived'로 변경
  async setOrderStatusArrived(orderId) {
    try {
      const options = {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          cache: 'no-cache',
        },
        body: JSON.stringify({ orderId }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/orders/status/set-arrived`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async setOrderStatusRefunding(orderId) {
    try {
      const options = {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          cache: 'no-cache',
        },
        body: JSON.stringify({ orderId }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/orders/status/set-refunding`,
        options,
      )
      const data = await res.json()
      if (data?.status === 200) {
        return data
      }
      return null
    } catch (err) {
      console.log(err)
    }
  }

  // Yechan 해당 주문의 status를 'pre-shipping'으로 변경
  async setOrderStatusPreShipping(orderId) {
    try {
      const options = {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          cache: 'no-cache',
        },
        body: JSON.stringify({ orderId }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/orders/status/set-pre-shipping`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async postOrderShippingInfo(orderId, shippingInfo) {
    try {
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          cache: 'no-cache',
        },
        body: JSON.stringify({ orderId, shippingInfo }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/shipping-info`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async postRefundShippingInfosInBulk(shippingInfos, status) {
    try {
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          cache: 'no-cache',
        },
        body: JSON.stringify({ shippingInfos, status }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/refund-shipping-info/bulk`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async postShippingInfosInBulk(shippingInfos, status) {
    try {
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          cache: 'no-cache',
        },
        body: JSON.stringify({ shippingInfos, status }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/shipping-info/bulk`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  // 셀러페이지에서 송장번호 업데이트하면 도는 API
  async editShippingInfosInBulk(shippingInfos) {
    try {
      const options = {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          cache: 'no-cache',
        },
        body: JSON.stringify({ shippingInfos }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/edit-shipping-info/bulk`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  // 24.01.05 기준 사용되는 곳 없음
  async setOrderShippingInfo(orderId, shippingInfo) {
    try {
      const options = {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          cache: 'no-cache',
        },
        body: JSON.stringify({
          orderId,
          shippingInfo,
        }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/orders/edit-shipping-info`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async postOrderCancelingShippingInfo(orderId, cancelInfo) {
    try {
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ orderId, cancelInfo }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/cancel-shipping-info`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async postOrderRefundingInfo(orderId, refundInfo) {
    try {
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ orderId, refundInfo }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/refund-info`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getAutoComplete(text) {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/brand-autocomplete?text=${text}`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getBrandTotalInfo() {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/brandtotal-info`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async brandApplicationRequest(brandName) {
    try {
      const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          brandName,
        }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/brand-request`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async updateDepartureDeadlineBySellerDelay(orderIds, delayDate) {
    try {
      const options = {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          orderIds,
          delayDate,
        }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/logistics/departure-deadline-delay/update`,
        options,
      )
      const data = await res.json()
      if (data?.status === 200) {
        return data
      }
      return { status: 400 }
    } catch (err) {
      console.log(err)
    }
  }

  async updateExtremePrice(itemId, optionsInfo, type, optionIndices = {}) {
    try {
      const options = {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ itemId, optionsInfo, type, optionIndices }),
      }

      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/cheapability/extreme-price/update`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async updateExtremeItemPrice(itemId, optionsInfo) {
    try {
      const options = {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ itemId, optionsInfo }),
      }

      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/cheapability/extreme-price/item/update`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async uploadBrandSeller(
    urlLink,
    subUrlLink,
    sellerType,
    brands,
    phoneNumber,
  ) {
    try {
      const options = {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          urlLink,
          subUrlLink,
          sellerType,
          brands,
          phoneNumber,
        }),
      }

      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/brand-seller-upload`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async approveOrderCancel(
    orderId,
    totalPrice,
    shippingFee,
    returnFee,
    totalReturnFee,
  ) {
    try {
      const options = {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          orderId,
          totalPrice,
          shippingFee,
          returnFee,
          totalReturnFee,
        }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/orders`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async notifyUserCancelEvent(orderId, cancelReason) {
    try {
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          orderId,
          cancelReason,
        }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/notifyUserCancelEvent`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async duplicateItem(itemId) {
    try {
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ itemId }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/items/duplicate`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getTotalExternalReviewTargetItemIds() {
    try {
      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/items/external-url/get-all-ids`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getItemsInfoByItemIds(targetItemIds) {
    try {
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ targetItemIds }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/items/get-all-items-info`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getItemsInfoWithStorageByItemIds(targetItemIds) {
    try {
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ targetItemIds }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/items/get-items-info-with-storage`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  // async getDayOffInfoBySellerId() {
  //   try {
  //     const options = {
  //       method: 'GET',
  //       headers: {
  //         'Content-Type': 'application/json',
  //       },
  //     }
  //     const res = await fetchWrapper(
  //       `${configs.backendUrl}/sellers/items/get-day-off-info`,
  //       options,
  //     )
  //     const data = await res.json()
  //     return data
  //   } catch (err) {
  //     console.log(err)
  //   }
  // }

  async getFreshItemInfoBySellerId() {
    try {
      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/items/get-fresh-item-info`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getStorageLocationInfoBySellerId() {
    try {
      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/items/get-storage-location-info`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async updateStorageLocationInfo(condition) {
    try {
      const options = {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ condition }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/items/update-storage-location-info`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  // async updateDayOffInfo(condition) {
  //   try {
  //     const options = {
  //       method: 'PUT',
  //       headers: {
  //         'Content-Type': 'application/json',
  //       },
  //       body: JSON.stringify({ condition }),
  //     }
  //     const res = await fetchWrapper(
  //       `${configs.backendUrl}/sellers/items/update-day-off-info`,
  //       options,
  //     )
  //     const data = await res.json()
  //     return data
  //   } catch (err) {
  //     console.log(err)
  //   }
  // }

  async updateFreshItemInfo(condition) {
    try {
      const options = {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ condition }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/items/update-fresh-item-info`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async checkIndexForCj(indexCondition) {
    try {
      const options = {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ indexCondition }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/check-index-for-cj`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async updateIndexForCj(indexCondition) {
    try {
      const options = {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ indexCondition }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/update-index-for-cj`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async updateSellerServiceConsent(condition) {
    // params 로 type, subType, status 를 받아옴
    try {
      const options = {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ condition }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/update-service-consent-info`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getSellerServiceConsentInfo(condition) {
    // params 로 type, subType, status 를 받아옴
    try {
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ condition }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/get-service-consent-info`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async updateFastArrivalItems(condition) {
    // condition params 로 {itemId, itemTitle, type, status} 를 받아옴
    try {
      const options = {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ condition }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/items/update-fast-arrival-items-info`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async withdrawFastArrivalItemsBySeller(condition) {
    // condition params 로 {type, status} 를 받아옴
    try {
      const options = {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ condition }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/items/withdraw-fast-arrival-items-by-seller`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async updateCombinedDeliveryStatusForDelivus(orderData) {
    try {
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ orderData }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/orders/combined-delivery-status-for-delivus`,
        options,
      )
      const data = await res.json()
      if (data?.status === 200) {
        return data
      }
      return null
    } catch (err) {
      console.log(err)
    }
  }

  async updateDeliveryStatusForDelivus(orderIds) {
    try {
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ orderIds }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/orders/delivery-status-for-delivus`,
        options,
      )
      const data = await res.json()
      if (data?.status === 200) {
        return data
      }
      return null
    } catch (err) {
      console.log(err)
    }
  }

  async createCombinedOrderDataForShipping(orderData) {
    try {
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ orderData }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/orders/order-creation-for-combined-shipping`,
        options,
      )
      const data = await res.json()
      if (data?.status === 200) {
        return data
      }
      return null
    } catch (err) {
      console.log(err)
    }
  }

  async createOrderDataForShipping(orderIds) {
    try {
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ orderIds }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/orders/order-creation-for-shipping`,
        options,
      )
      const data = await res.json()
      if (data?.status === 200) {
        return data
      }
      return null
    } catch (err) {
      console.log(err)
    }
  }

  async requestRefundingForCj(orderData) {
    try {
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ orderData }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/orders/cj-refunding-request`,
        options,
      )
      const result = await res.json()
      if (result?.status === 200) {
        return result?.data
      }
      return null
    } catch (err) {
      console.log(err)
    }
  }

  async requestCombinedShippingForCjLogistics(orderData) {
    try {
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ orderData }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/orders/cj-combined-shipping-request`,
        options,
      )
      const result = await res.json()
      if (result?.status === 200) {
        return result?.data
      }
      return null
    } catch (err) {
      console.log(err)
    }
  }

  async requestShippingForCjLogistics(orderData) {
    try {
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ orderData }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/orders/cj-shipping-request`,
        options,
      )
      const result = await res.json()
      if (result?.status === 200) {
        return result?.data
      }
      return null
    } catch (err) {
      console.log(err)
    }
  }

  async createShippingNumberForCjLogistics(orderIds) {
    try {
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ orderIds }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/orders/cj-shipping-number-creation`,
        options,
      )
      const result = await res.json()
      if (result?.status === 200) {
        return result?.data
      }
      return null
    } catch (err) {
      console.log(err)
    }
  }

  async notifyUserRefundEventWithOutPickUp(orderId, refundReason) {
    try {
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          orderId,
          refundReason,
        }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/notification-refund-without-pickup`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async requestRefundingForDelivus(orderType, orderId) {
    try {
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ orderType, orderId }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/orders/refund-pickup-for-delivus`,
        options,
      )
      const data = await res.json()
      if (data?.status === 200) {
        return data
      }
      return null
    } catch (err) {
      console.log(err)
    }
  }

  async requestCombinedShippingForDelivus(orderData) {
    try {
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ orderData }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/orders/request-combined-shipping-for-delivus`,
        options,
      )
      const data = await res.json()
      if (data?.status === 200) {
        return data
      }
      return null
    } catch (err) {
      console.log(err)
    }
  }

  async requestShippingForDelivus(orderIds) {
    try {
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ orderIds }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/orders/request-shipping-for-delivus`,
        options,
      )
      const data = await res.json()
      if (data?.status === 200) {
        return data
      }
      return null
    } catch (err) {
      console.log(err)
    }
  }

  async requestCancelingShippingForDelivus(orderId) {
    try {
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ orderId }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/orders/request-canceling-shipping-for-delivus`,
        options,
      )
      const data = await res.json()
      if (data?.status === 200) {
        return data
      }
      return null
    } catch (err) {
      console.log(err)
    }
  }

  async requestCancelingShippingForCj(orderId) {
    try {
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ orderId }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/orders/request-canceling-shipping-for-cj`,
        options,
      )
      const data = await res.json()
      if (data?.status === 200) {
        return data
      }
      return null
    } catch (err) {
      console.log(err)
    }
  }

  async prepareShippingForDelivus(trackingNumbers) {
    try {
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ trackingNumbers }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/orders/prepared-shipping-for-delivus`,
        options,
      )
      const data = await res.json()
      if (data?.status === 200) {
        return data
      }
      return null
    } catch (err) {
      console.log(err)
    }
  }

  async registerExternalUrlChunk(updateCandidate) {
    try {
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ updateCandidate }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/items/external-url/post/chunk`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getExternalUrl(itemId) {
    try {
      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/items/external-url/get?itemId=${itemId}`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async registerExternalUrl(itemId, platform, externalUrl) {
    try {
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ itemId, platform, externalUrl }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/items/external-url/post`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async deleteExternalUrl(itemId) {
    try {
      const options = {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ itemId }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/items/external-url/delete`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async uploadItemMainImages(itemId, mainImagesData) {
    try {
      const uploadImage = new FormData()
      uploadImage.append('itemId', itemId)
      mainImagesData.forEach((image, index) => {
        uploadImage.append(
          'image',
          dataUriToBlob(image.sourceUri),
          `image_${index}.${image.sourceUri.split(';')[0].split('/')[1]}`,
        )
      })
      const options = {
        method: 'PUT',
        body: uploadImage,
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/items/main-images`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async uploadTradeMarkImages(sellerId, tradeMarkRightImage) {
    try {
      const uploadImage = new FormData()
      uploadImage.append('sellerId', sellerId)
      tradeMarkRightImage.forEach((image, index) => {
        uploadImage.append(
          'image',
          dataUriToBlob(image.sourceUri),
          `image_${index}.${image.sourceUri.split(';')[0].split('/')[1]}`,
        )
      })
      const options = {
        method: 'PUT',
        body: uploadImage,
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/trademark-right-images`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async uploadBrandHeadOfficeTradeMarkImages(sellerId, tradeMarkRightImage) {
    try {
      const uploadImage = new FormData()
      uploadImage.append('sellerId', sellerId)
      tradeMarkRightImage.forEach((image, index) => {
        uploadImage.append(
          'image',
          dataUriToBlob(image.sourceUri),
          `image_${index}.${image.sourceUri.split(';')[0].split('/')[1]}`,
        )
      })
      const options = {
        method: 'PUT',
        body: uploadImage,
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/trademark-right-images-headoffice`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async uploadItemMainImagesForUpdate(itemId, mainImagesData) {
    try {
      const uploadImage = new FormData()
      uploadImage.append('itemId', itemId)
      uploadImage.append(
        'mainImageUrisInfo',
        JSON.stringify(
          mainImagesData.map((image, index) => {
            if (image.isUploaded) {
              return { ...image, index }
            }
            return { ...image, sourceUri: '', index }
          }),
        ),
      )
      const uploadingImageIndices = []
      mainImagesData
        // .filter((image) => !image.isUploaded)
        .forEach((image, index) => {
          if (!image.isUploaded) {
            uploadImage.append(
              'image',
              dataUriToBlob(image.sourceUri),
              `image_${index}.${image.sourceUri.split(';')[0].split('/')[1]}`,
            )
            uploadingImageIndices.push(index)
          }
        })
      uploadImage.append(
        'uploadingImageIndices',
        JSON.stringify(uploadingImageIndices),
      )
      const options = {
        method: 'PUT',
        body: uploadImage,
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/items/main-images/updates`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async uploadItemDetailImages(itemId, detailImagesData) {
    try {
      const uploadImage = new FormData()
      uploadImage.append('itemId', itemId)
      detailImagesData.forEach((image, index) => {
        uploadImage.append(
          'image',
          dataUriToBlob(image.sourceUri),
          `image_${index}.${image.sourceUri.split(';')[0].split('/')[1]}`,
        )
      })
      const options = {
        method: 'PUT',
        body: uploadImage,
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/items/detail-images`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async uploadItemDetailImagesForUpdate(itemId, detailImagesData) {
    try {
      const uploadImage = new FormData()
      uploadImage.append('itemId', itemId)
      uploadImage.append(
        'detailImageUrisInfo',
        JSON.stringify(
          detailImagesData.map((image, index) => {
            if (image.isUploaded) {
              return { ...image, index }
            }
            return { ...image, sourceUri: '', index }
          }),
        ),
      )
      const uploadingImageIndices = []
      detailImagesData
        // .filter((image) => !image.isUploaded)
        .forEach((image, index) => {
          if (!image.isUploaded) {
            uploadImage.append(
              'image',
              dataUriToBlob(image.sourceUri),
              `image_${index}.${image.sourceUri.split(';')[0].split('/')[1]}`,
            )
            uploadingImageIndices.push(index)
          }
        })
      uploadImage.append(
        'uploadingImageIndices',
        JSON.stringify(uploadingImageIndices),
      )
      const options = {
        method: 'PUT',
        body: uploadImage,
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/items/detail-images/updates`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async uploadBiddingItemMainImages(itemId, mainImagesData) {
    try {
      const uploadImage = new FormData()
      uploadImage.append('itemId', itemId)
      mainImagesData.forEach((image, index) => {
        uploadImage.append(
          'image',
          dataUriToBlob(image.sourceUri),
          `image_${index}.${image.sourceUri.split(';')[0].split('/')[1]}`,
        )
      })
      const options = {
        method: 'PUT',
        body: uploadImage,
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/items/bidding-main-images`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async uploadBiddingItemDetailImages(itemId, detailImagesData) {
    try {
      const uploadImage = new FormData()
      uploadImage.append('itemId', itemId)
      detailImagesData.forEach((image, index) => {
        uploadImage.append(
          'image',
          dataUriToBlob(image.sourceUri),
          `image_${index}.${image.sourceUri.split(';')[0].split('/')[1]}`,
        )
      })
      const options = {
        method: 'PUT',
        body: uploadImage,
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/items/bidding-detail-images`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async uploadAgreeToTnC() {
    try {
      const options = {
        method: 'POST',
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/TnC`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async applyDirectPurchase(applyInfo) {
    try {
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ applyInfo }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/direct-purchase`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async applyAlwayzCooperativeSeller(applyInfo) {
    try {
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ applyInfo }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/cooperative-seller`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async uploadAgreeToSpecificPolicy(type) {
    try {
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ type }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/specific-policy`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async setItemSoldOut(itemId) {
    try {
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ itemId }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/items/sold-out`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async setManyItemsSoldOut(itemIdList) {
    try {
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ itemIdList }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/items/sold-out-many`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async setItemSalesResume(itemId) {
    try {
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ itemId }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/items/resume`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async setManyItemSalesResume(itemIdList) {
    try {
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ itemIdList }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/items/resume-many`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async initiateCancelOrderBySeller(userId, orderId, cancelInfo) {
    try {
      const options = {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          userId,
          orderId,
          cancelInfo,
        }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/orders/manual`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getOrdererInfo(orderId) {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/orders/orderers?orderId=${orderId}`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  // 현식 끝

  async getSellerBySellerName(sellerName) {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${
          configs.backendUrl
        }/sellers/seller-name-validity?sellerName=${encodeURIComponent(
          sellerName,
        )}`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async checkLureDealCandidateItem(bizNum, itemTitle) {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/check-lure-deal-candidate?bizNum=${bizNum}&itemTitle=${itemTitle}`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async uploadItemImage(imageList) {
    try {
      const uploadImage = new FormData()
      // uploadImage.append('name', 'Image Upload')
      // console.log(
      //   `type: ${`image/${
      //     imageList[0].sourceUri.split(';')[0].split('/')[1]
      //   }`}, data: ${imageList[0].sourceUri.replace(
      //     /^data:image\/\w+;base64,/,
      //     '',
      //   )}`,
      // )
      imageList.forEach((image, index) => {
        // uploadImage.append('image', {
        //   type: `image/${image.sourceUri.split(';')[0].split('/')[1]}`,
        //   // data: Buffer.from(
        //   //   image.sourceUri.replace(/^data:image\/\w+;base64,/, ''),
        //   //   'base64',
        //   // ),
        //   // data: `${image.sourceUri.replace(/^data:image\/\w+;base64,/, '')}`,
        //   // uri: image.sourceUri,
        //   data: dataUriToBlob(image.sourceUri),
        //   name: `image_${index}.${image.sourceUri.split(';')[0].split('/')[1]}`,
        // })
        // uploadImage.append(
        //   'image',
        //   Buffer.from(
        //     image.sourceUri.replace(/^data:image\/\w+;base64,/, ''),
        //     'base64',
        //   ),
        // )

        // uploadImage.append('image', image)
        uploadImage.append(
          'image',
          dataUriToBlob(image.sourceUri),
          `image_${index}.${image.sourceUri.split(';')[0].split('/')[1]}`,
        )
      })

      // for (const pair of uploadImage.entries()) {
      //   console.log(`${pair[0]}, ${JSON.stringify(pair[1])}`)
      // }
      const options = {
        method: 'POST',
        body: uploadImage,
      }
      const res = await fetchWrapper(`${configs.backendUrl}/images`, options)
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async sendUserNotification(
    userId,
    messageTitle,
    messageContent,
    pushTitle,
    pushContent,
    pushUrl,
  ) {
    try {
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          userId,
          messageTitle,
          messageContent,
          pushTitle,
          pushContent,
          pushUrl,
        }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/users/notifications`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getCouponMarketingItems() {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/items/seller-marketing/coupon-marketing`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getMultipurchaseCouponItems() {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/items/seller-marketing/multipurchase-coupon`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getFollowCouponItems() {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/items/seller-marketing/follow-coupon`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getPhotoReviewCouponItems() {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/items/seller-marketing/photoreview-coupon`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async loadItemsRank(sellerId) {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/item-rank?sellerId=${sellerId}`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async registerCouponMarketingItem(
    // sellerId,
    itemId,
    discountType,
    checkMinOrderPrice,
    minOrderPrice,
    value,
    expirationPeriod,
    checkIssuableCouponLimit,
    totalIssuingCoupon,
  ) {
    try {
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          // sellerId,
          itemId,
          discountType,
          checkMinOrderPrice,
          minOrderPrice,
          value,
          expirationPeriod,
          checkIssuableCouponLimit,
          totalIssuingCoupon,
        }),
      }

      const res = await fetchWrapper(
        `${configs.backendUrl}/items/coupon-marketing/register`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async registerCouponInfo(itemId, couponType, couponInfo) {
    try {
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          itemId,
          couponType,
          couponInfo,
        }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/items/coupon/register`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getCouponInfo(itemId, couponType) {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/items/coupon/info?itemId=${itemId}&couponType=${couponType}`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async updateCouponInfo(itemId, couponType, couponInfo) {
    try {
      const options = {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          itemId,
          couponType,
          couponInfo,
        }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/items/coupon/update`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async updateWinCategorySeller(
    sellerName,
    sellerDisplayName,
    recommenderName,
  ) {
    try {
      const options = {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          sellerName,
          sellerDisplayName,
          recommenderName,
        }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/win-category-new-seller`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async cancelCouponInfo(itemId, couponType, couponInfo) {
    try {
      const options = {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          itemId,
          couponType,
          couponInfo,
        }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/items/coupon/cancel`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async registerSellerCouponInfo(couponType, couponInfo) {
    try {
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          couponType,
          couponInfo: { ...couponInfo },
        }),
      }

      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/coupon/register`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getSellerCouponInfo(couponType) {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/coupon/info?couponType=${couponType}`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async updateSellerCouponInfoV2(couponType, couponInfo) {
    try {
      const options = {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          couponType,
          couponInfo,
        }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/coupon/update`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async cancelSellerCouponInfo(couponType) {
    try {
      const options = {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          couponType,
        }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/coupon/cancel`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async registerMultipurchaseCouponItem(
    itemId,
    discountType,
    value,
    expirationPeriod,
    totalIssuingCoupon,
  ) {
    try {
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          itemId,
          discountType,
          value,
          expirationPeriod,
          totalIssuingCoupon,
        }),
      }

      const res = await fetchWrapper(
        `${configs.backendUrl}/items/multipurchase-coupon/register`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async registerFollowCoupon(
    itemId,
    discountType,
    value,
    expirationPeriod,
    totalIssuingCoupon,
  ) {
    try {
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          itemId,
          discountType,
          value,
          expirationPeriod,
          totalIssuingCoupon,
        }),
      }

      const res = await fetchWrapper(
        `${configs.backendUrl}/items/follow-coupon/register`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async registerPhotoReviewCoupon(value, totalIssuingCoupon) {
    try {
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          value,
          totalIssuingCoupon,
        }),
      }

      const res = await fetchWrapper(
        `${configs.backendUrl}/items/photoreview-coupon/register`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async registerMultipurchaseCouponEveryItem() {
    try {
      const options = {
        method: 'POST',
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/items/multipurchase-coupon/register/every-item`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async registerFollowCouponEveryItem() {
    try {
      const options = {
        method: 'POST',
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/items/follow-coupon/register/every-item`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async editCouponMarketingItem(sellerMarketingId, newInfo) {
    try {
      const options = {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          sellerMarketingId,
          newInfo,
        }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/items/coupon-marketing/edit`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async editCouponItem(sellerMarketingId, newInfo) {
    try {
      const options = {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          sellerMarketingId,
          newInfo,
        }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/items/multipurchase-coupon/edit`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async stopCouponMarketingItem(sellerMarketingId, couponInfo) {
    try {
      const options = {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          sellerMarketingId,
          couponInfo,
        }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/items/coupon-marketing/stop`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async stopCouponItem(sellerMarketingId, couponInfo) {
    try {
      const options = {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          sellerMarketingId,
          couponInfo,
        }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/items/coupon/stop`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async updateSellerTutorialInfo(stageName) {
    try {
      const options = {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          stageName,
        }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/tutorial`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async clearSellerQuest(questName, stageName) {
    try {
      const options = {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          questName,
          stageName,
        }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/quest`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async updateAllSellerItemShippingDaysToTwoDays() {
    try {
      const options = {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/items/update-shipping-days-to-two-days`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async updateSellerCouponInfo(sellerId) {
    try {
      const options = {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          sellerId,
        }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/seller-coupon-info`,
        options,
      )
      const data = res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getReviewEventAvailableSku() {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/review-event-skus`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getUnitOptionBiddingInfo(unitOptionId, itemId, optionId) {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/unit-option/bidding?unitOptionId=${unitOptionId}&itemId=${itemId}&optionId=${optionId}`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getGeneralOptionBiddingInfo(generalOptionId, itemId, optionId) {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/general-option/bidding?generalOptionId=${generalOptionId}&itemId=${itemId}&optionId=${optionId}`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getUnitOptionBiddingList(unitOptionId, itemId, optionId) {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/unit-option/bidding/live?unitOptionId=${unitOptionId}&itemId=${itemId}&optionId=${optionId}`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getGeneralOptionLiveBiddingList(generalOptionId, itemId, optionId) {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/general-option/bidding/live?generalOptionId=${generalOptionId}&itemId=${itemId}&optionId=${optionId}`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getUnitOption(condition, page = 1, limit = 10) {
    try {
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          condition,
          page,
          limit,
        }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/unit-option/list`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getGeneralOption(condition, page = 1, limit = 10) {
    try {
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          condition,
          page,
          limit,
        }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/general-option/list`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getUnitOptionTotalCount(condition) {
    try {
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          condition,
        }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/unit-option/total`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getGeneralOptionTotalCount(condition) {
    try {
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          condition,
        }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/general-option/total`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getMappedUnitOptionIds() {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/unit-option/mapped-ids`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async postCpmAdSet({
    costPerImpression,
    maxImpressions,
    startDate,
    endDate,
    targeting,
    itemIds,
    adType,
  }) {
    try {
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          costPerImpression,
          maxImpressions,
          startDate,
          endDate,
          targeting,
          itemIds,
          adType,
        }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/ad-cpm/ad-cpm-set`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getCpmAdSetList({ size, page, startDate, endDate }) {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/ad-cpm/ad-cpm-set-list?size=${size}&page=${page}&startDate=${startDate}&endDate=${endDate}`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getRecentCpmAdSetList({ days }) {
    try {
      const options = { method: 'GET' }
      const res = await fetchWrapper(
        `${configs.backendUrl}/ad-cpm/ad-cpm-set-list-recent?days=${days}`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async cancelCpmAdSet({ adCpmSetId }) {
    try {
      const options = {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          adSetId: adCpmSetId,
        }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/ad-cpm/ad-cpm-set`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getItemsByAdSetId({ adCpmSetId }) {
    try {
      const options = { method: 'GET' }
      const res = await fetchWrapper(
        `${configs.backendUrl}/ad-cpm/ad-cpm-set-item-info-list?adCpmSetId=${adCpmSetId}`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getFreeAdCPMCandidateHistory() {
    try {
      const options = { method: 'GET' }
      const res = await fetchWrapper(
        `${configs.backendUrl}/ad-cpm/free-ad-cpm-candidate-history`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getCpmAdSetAnalytics({ adSetId }) {
    try {
      const options = { method: 'GET' }
      const res = await fetchWrapper(
        `${configs.backendUrl}/ad-cpm/ad-cpm-set-analytics?adSetId=${adSetId}`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getCpmAdSetsAnalyticsBulk({ adSetIds }) {
    try {
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ adSetIds }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/ad-cpm/ad-cpm-set-analytics-bulk`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async updateUnitOptionBiddingInfo({
    biddingOptionId,
    itemId,
    optionId,
    values,
    isReclaimed,
  }) {
    // console.log('unitOptionId: ', biddingOptionId)
    try {
      const options = {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          unitOptionId: biddingOptionId,
          itemId,
          optionId,
          values,
          isReclaimed,
        }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/unit-option/bidding`,
        options,
      )
      const data = res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async deleteBiddingInfo({ biddingDocId, type }) {
    try {
      const options = {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          biddingDocId,
          type,
          deletedBy: 'seller',
        }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/bidding/option-bidding`,
        options,
      )
      const data = res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async updateGeneralOptionBiddingInfo({
    biddingOptionId,
    itemId,
    optionId,
    values,
    isReclaimed,
  }) {
    // console.log('generalOptionId: ', biddingOptionId)
    try {
      const options = {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          generalOptionId: biddingOptionId,
          itemId,
          optionId,
          values,
          isReclaimed,
        }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/general-option/bidding`,
        options,
      )
      const data = res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async updateGuerrillaBiddingInfo(guerrillaBiddingId, data) {
    try {
      const options = {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          guerrillaBiddingId,
          data,
        }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/bidding/guerrilla-bidding`,
        options,
      )
      const result = res.json()
      return result
    } catch (err) {
      console.log(err)
    }
  }

  async deleteGuerrillaBiddingInfo(guerrillaBiddingId) {
    try {
      const options = {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          guerrillaBiddingId,
        }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/bidding/guerrilla-bidding`,
        options,
      )
      const result = res.json()
      return result
    } catch (err) {
      console.log(err)
    }
  }

  async mappingItemToUnitOption(unitOptionId, itemId, optionId) {
    try {
      const options = {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          unitOptionId,
          itemId,
          optionId,
        }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/unit-option/mapping`,
        options,
      )
      const data = res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async mappingItemToGeneralOption(generalOptionId, itemId, optionId) {
    try {
      const options = {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          generalOptionId,
          itemId,
          optionId,
        }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/general-option/mapping`,
        options,
      )
      const data = res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async setReviewEventRegistered(itemId, eventStockNumber, value) {
    try {
      const options = {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          itemId,
          eventStockNumber,
          value,
        }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/register-review-event`,
        options,
      )
      const data = res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async setReviewEventUpdated(itemId, value) {
    try {
      const options = {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          itemId,
          value,
        }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/update-review-event`,
        options,
      )
      const data = res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async deleteDealOptionsInfo(itemId, dealType) {
    try {
      const options = {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          itemId,
          dealType,
        }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/delete-deal-options`,
        options,
      )
      const data = res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async deletePreviousDeals(
    itemId,
    deletedReason = 'seller changed price or option',
  ) {
    try {
      const options = {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          itemId,
          deletedReason,
        }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/all-deal`,
        options,
      )
      const data = res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async registerDealOptionsInfo(
    itemId,
    itemTitle,
    dealType,
    dealOptionsInfo,
    maxDiscountRate = null,
  ) {
    try {
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          itemId,
          itemTitle,
          dealType,
          dealOptionsInfo,
          maxDiscountRate,
        }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/deal-options`,
        options,
      )
      const data = res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async registerNewYear24DealOptionsInfo(
    itemId,
    itemTitle,
    dealType,
    dealOptionsInfo,
    newYear24DealMetaData,
  ) {
    try {
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          itemId,
          itemTitle,
          dealType,
          dealOptionsInfo,
          newYear24DealMetaData,
        }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/deal-options/new-year-24`,
        options,
      )
      const data = res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async updateDealOptionsInfosWithDiscountRate({ dealIds, discountRate }) {
    try {
      const options = {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ dealIds, discountRate }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/deal-options/discount`,
        options,
      )
      const data = res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async updateDealOptionsInfo(itemId, dealType, dealOptionsInfo) {
    try {
      const options = {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          itemId,
          dealType,
          dealOptionsInfo,
        }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/deal-options-update`,
        options,
      )
      const data = res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getDealOptionsInfo(itemId, dealType) {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/deal-options?itemId=${itemId}&dealType=${dealType}`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getDealItemByDealType(dealType = 'tenMinDeal', extraCondition = {}) {
    try {
      const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          dealType,
          extraCondition,
        }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/deal-items`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getDealOptionsInfoArray(itemIds, dealType) {
    try {
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          itemIds,
          dealType,
        }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/deal-options/list`,
        options,
      )
      const data = res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async verifySpecialCode(type, code) {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/verify-special-code?type=${type}&code=${code}`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async registerSuggestion(
    sellerId,
    itemId,
    suggestedLowestPriceLink,
    suggestion,
    suggestedLowestPrice,
    type,
  ) {
    try {
      const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          sellerId,
          itemId,
          suggestedLowestPriceLink,
          suggestion,
          suggestedLowestPrice,
          type,
        }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/register-suggestion`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async setItemManualCode(itemId, manualItemCode) {
    try {
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          itemId,
          manualItemCode,
        }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/manual-item-code`,
        options,
      )
      const data = res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async setItemMatchindID(itemId, matchingID) {
    try {
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          itemId,
          matchingID,
        }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/matching-id`,
        options,
      )
      const data = res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async loadSettlementExceptAmount(settlementAmount) {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/settlement-except-amount?settlementAmount=${settlementAmount}`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getItemPenaltyInfo(loadedItemsNum, batchSize, sortType, focusItemId) {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/item-penalty?loadedItemsNum=${loadedItemsNum}&batchSize=${batchSize}&sortType=${sortType}&focusItemId=${focusItemId}`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async submitPenaltyExcuse(penaltyId, excuse, afterService, preventPlan) {
    try {
      const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ penaltyId, excuse, afterService, preventPlan }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/item-penalty/seller-submit-penalty-excuse`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async loadDashboardInfo() {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/dashboardInfo`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async loadItemImpTrend(itemId) {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/items/imp-trend-info?itemId=${itemId}`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getBiddingItem(itemId) {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/bidding-item?itemId=${itemId}`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getItemInfoForBidding(itemId) {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/item-info-for-bidding?itemId=${itemId}`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getNotices() {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/notice`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getBanner() {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/banner`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getLiveInfo() {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/live-info`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getNudgingBotContent(focusId) {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/nudgingBot?focusId=${focusId}`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getReviewExperienceItems() {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/review-experience-skus`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getReviewExperienceItemsV3() {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/review-experience-skus-v3`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async registerReviewExperience(item) {
    try {
      const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ item }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/register-review-experience`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getSampleExperienceItems() {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/sample-experience-skus`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async registerSampleExperience(item, sampleName, sampleStock) {
    try {
      const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ item, sampleName, sampleStock }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/register-sample-experience`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async stopSampleExperience(itemId, dealType) {
    try {
      const options = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ itemId, dealType }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/stop-sample-experience`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async registerFastShippingItem(item) {
    try {
      const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ item }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/register-fast-shipping`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async unRegisterFastShippingItem(item) {
    try {
      const options = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ item }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/unregister-fast-shipping`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async registerTodayDepartureInfo(todayDepartureInfo) {
    try {
      const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ todayDepartureInfo }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/items/todayDeparture`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async registerTodayDepartureInfoAllItems(todayDepartureInfo) {
    try {
      const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ todayDepartureInfo }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/items/todayDeparture/all`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async unRegisterTodayDepartureInfo(itemIds) {
    try {
      const options = {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(itemIds),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/items/todayDeparture`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async unRegisterTodayDepartureInfoAllItems() {
    try {
      const options = {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json' },
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/items/todayDeparture/all`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async createSellerTodayDepartureStatus() {
    try {
      const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/todayDepartureStatus`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getSellerTodayDepartureStatus() {
    try {
      const options = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/todayDepartureStatus`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async recordRepurchaseCoupon(amount) {
    try {
      const options = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ amount }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/repurchase-coupon`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async cancelRepurchaseCoupon() {
    try {
      const options = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({}),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/cancel-repurchase-coupon`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async recordFollowingCoupon(amount) {
    try {
      const options = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ amount }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/following-coupon`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async loadCouponPushItems() {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/coupon-push-items`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async registerCouponPush(params) {
    try {
      const options = {
        method: 'POST',
        headers: { 'Content-type': 'application/json' },
        body: JSON.stringify({ params }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/register-coupon-push`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async loadSellerCouponPushInfo() {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/seller-coupon-push`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async registerBusinessRegistrationNumberImage(
    businessRegistrationNumberImage,
    sellerId,
  ) {
    try {
      const uploadImage = new FormData()
      uploadImage.append('sellerId', sellerId)
      businessRegistrationNumberImage.forEach((image, index) => {
        uploadImage.append(
          'image',
          dataUriToBlob(image.sourceUri),
          `image_${index}.${image.sourceUri.split(';')[0].split('/')[1]}`,
        )
      })
      const options = {
        method: 'PUT',
        body: uploadImage,
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/business-registration-number-image`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async uploadBusinessNumberImage(businessRegistrationNumberImage, sellerId) {
    try {
      const uploadImage = new FormData()
      uploadImage.append('sellerId', sellerId)
      businessRegistrationNumberImage.forEach((image, index) => {
        uploadImage.append(
          'image',
          dataUriToBlob(image.sourceUri),
          `image_${index}.${image.sourceUri.split(';')[0].split('/')[1]}`,
        )
      })
      const options = {
        method: 'PUT',
        body: uploadImage,
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/businessRegistrationNumberImage`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async sendKakaoNotification(info) {
    try {
      const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ ...info }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/users/kakao-notification`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getAdCreditInfo() {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/ad-credit`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async deleteAdCreditCardInfo(cardInfo) {
    try {
      const options = {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ cardInfo }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/ad-credit/card/delete`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async buyAdCredit(paymentMethodId, creditAmount, paymentStartTime) {
    try {
      const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          paymentMethodId,
          creditAmount,
          paymentStartTime,
        }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/ad-credit/buy/nice-pay`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async cancelAdCredit(logId, creditAmount, cancelMsg) {
    try {
      const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          logId,
          creditAmount,
          cancelMsg,
        }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/ad-credit/cancel/nice-pay`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async addFreeAdCreditForQuest(questName) {
    try {
      const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          questName,
        }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/ad-credit/quest-reward`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async updateSellerQuestRewardStatus(questName) {
    try {
      const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          questName,
        }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/quest/quest-reward`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getAdChartData(days) {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/ad-chart?days=${days}`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getAdCreditUsageOfCPS() {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/ad-credit-usage-of-cps`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getAdCreditLogInfo(limit, page) {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/ad-credit/log?limit=${limit}&page=${page}`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getAdCreditLogCount() {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/ad-credit/log/count`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getAdUsageLogCount(startDate, endDate) {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/ad-credit/usage/log/count?startDate=${startDate}&endDate=${endDate}`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getAdUsageLogInfo(limit, page, startDate, endDate) {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/ad-credit/usage/log?limit=${limit}&page=${page}&startDate=${startDate}&endDate=${endDate}`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async loadOrderData(orderId) {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/cs/order?orderId=${orderId}`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getAdItemCreditUsage(adType, onlyOngoing, date, unit = 'byAd') {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/ad-item-credit-usage?adType=${adType}&startDate=${date?.startDate}&endDate=${date?.endDate}&unit=${unit}&onlyOngoing=${onlyOngoing}`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getSellerRevenueByItemRank(sellerId) {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/revenue/rank?sellerId=${sellerId}`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async checkRegisteredBizNum(bizNum) {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/biz-number?bizNum=${bizNum}`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async checkSellerBizNum(bizNum) {
    try {
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ bizNum }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/biz-number/check`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async checkDealRerunTarget(dealType) {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/deal-rerun-target?dealType=${dealType}`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async checkDealGroceryTarget(dealType) {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/deal-grocery-target?dealType=${dealType}`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async checkDealFamilyTarget(dealType) {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/deal-family-target?dealType=${dealType}`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async checkDealFamilyBoostingTarget(dealType, status) {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/deal-family-boosting-target?dealType=${dealType}&status=${status}`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getDealOptionsInfoByIds(targetOptionsInfoIds = []) {
    try {
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ targetOptionsInfoIds }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/items/deal-info/ids`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async registerDealRerunTargetAll(dealType) {
    try {
      const options = {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ dealType }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/deal-rerun-target/register-all`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async registerDealTargetAll(dealType, selectedDiscount) {
    try {
      const options = {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ dealType, selectedDiscount }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/deal-target/register-all`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async itemWinnerLinkApplication(link) {
    try {
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ link }),
      }
      console.log(options, 'options')
      const res = await fetchWrapper(
        `${configs.backendUrl}/items/winner/link`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async confirmBusinessRegistrationNumber(
    businessRegistrationNumber,
    startDate,
    ceoName,
  ) {
    try {
      const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          businesses: [
            {
              b_no: businessRegistrationNumber,
              start_dt: startDate,
              p_nm: ceoName,
            },
          ],
        }),
      }
      const res = await fetch(
        `https://api.odcloud.kr/api/nts-businessman/v1/validate?serviceKey=PYDQ62i3Kkr0OOT1V8gNVmbIPeVchvCO3QrskjW63ex3hSJnEAVfYqSFl0oSWpf0T80WSW667JlrY%2B4QWqr5tw%3D%3D`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async setPhotoReviewUpdate(photoReviewCondition) {
    try {
      const options = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ photoReviewCondition }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/items/photoreview/update`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async tracePriceNudgeOfRankRevenueLog(sellerId) {
    try {
      const options = {
        method: 'PUT',
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/price/log/rank-revenue?sellerId=${sellerId}`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async tracePriceNudgeLog(sellerId, eventLocation, eventAction) {
    try {
      const options = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ eventLocation, eventAction }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/price/log/revenue-details?sellerId=${sellerId}`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getTheLowestPriceInfo(itemId, crawledCounts) {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/price/min-price?itemId=${itemId}&crawledCounts=${crawledCounts}`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getSellerRevenueByItemIdsDuringNDays(itemIds, n) {
    try {
      const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ itemIds, n }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/price/revenue/item-ids`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async loadSettlementExceptData(endDate) {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/settlement/except/excel?endDate=${endDate}`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getStoreAd(date, diff) {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/ad/store-ad/get-by-date?date=${date}&diff=${diff}`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getAlfarmMinigameAds(date, diff) {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/ad/alfarm-minigame-ad/get-by-date?date=${date}&diff=${diff}`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.error('Error fetching advertisement data:', err)
      throw err
    }
  }

  async getAlfarmLeavesAds(date, diff) {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/ad/alfarm-leaves-ad/get-by-date?date=${date}&diff=${diff}`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.error('Error fetching advertisement data:', err)
      throw err
    }
  }

  async getAlfarmWinnerDealAds(date, diff) {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/ad/alfarm-winner-deal-ad/get-by-date?date=${date}&diff=${diff}`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.error('Error fetching advertisement data:', err)
      throw err
    }
  }

  async loadUnansweredQuestions(questionType, limit, skip) {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/unanswered-questions?questionType=${questionType}&limit=${limit}&skip=${skip}`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getSellerPenaltyPoint() {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/penalty/point`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getSellerPenaltyStatus() {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/penalty/status`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async registerEggAd(adUnit) {
    try {
      const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ adUnit }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/ad/egg-ad/register`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async registerEggAdV2(adUnit) {
    try {
      const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ adUnit }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/ad/egg-ad/register/v2`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async registerEggAdForFreeEvent(adUnit) {
    try {
      const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ adUnit }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/ad/egg-ad/register/for-free-event`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getEggAd(date, diff) {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/ad/egg-ad/get-by-date?date=${date}&diff=${diff}`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.error('Error fetching advertisement data:', err)
      throw err
    }
  }

  async getTrendQuizAd(date) {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/ad/trend-quiz-ad/get-by-date?date=${date}`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async cancelEggAd(adId) {
    try {
      const options = {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          adId,
        }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/ad/egg-ad/delete`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async itemWinnerMappingApplication(targetItemInfo, itemWinnerId) {
    try {
      const options = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          targetItemInfo,
          itemWinnerId,
        }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/items/item-winner/mapping`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async itemWinnerPriceChange(
    itemId,
    itemWinnerId,
    oldOption,
    oldOptionsInfo,
    editPrice,
    individualPurchasePrice,
  ) {
    try {
      const options = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          itemId,
          itemWinnerId,
          oldOption,
          oldOptionsInfo,
          editPrice,
          individualPurchasePrice,
        }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/items/item-winner/price/change`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async cancelTrendQuizAd(adId) {
    try {
      const options = {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          adId,
        }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/ad/trend-quiz-ad/delete`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async checkAdditionalDiscountNudge() {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/check-additional-discount-nudge`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async postItemRandomReview(reviews, itemId) {
    try {
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ reviews, itemId }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/reviews/transfer`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getAutonomousPriceChangeListByItemId({ itemId }) {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/items/options-history?itemId=${itemId}`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getDealOptionsInfoByDealType(dealType, status, loadNum, chunkSize) {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/items/deal-info?dealType=${dealType}&status=${status}&loadNum=${loadNum}&chunkSize=${chunkSize}`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getTotalDealOptionsInfoByDealTypeWithSc(
    dealType,
    status,
    selectionCriteria,
  ) {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/items/deal-info-sc-total?dealType=${dealType}&status=${status}&selectionCriteria=${selectionCriteria}`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getDealOptionsInfoByDealTypeWithSc(
    dealType,
    status,
    loadNum,
    chunkSize,
    selectionCriteria,
  ) {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/items/deal-info-sc?dealType=${dealType}&status=${status}&loadNum=${loadNum}&chunkSize=${chunkSize}&selectionCriteria=${selectionCriteria}`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getTotalItemsInfoBySellerId() {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/items/total-item-info`,
        options,
      )
      const data = await res.json()
      if (data) {
        return data
      }
      return []
    } catch (err) {
      console.log(err)
    }
  }

  async getDealOptionsInfoByDealBoosting(
    dealType,
    status,
    boosting,
    loadNum,
    chunkSize,
  ) {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/items/deal-info/boosting?dealType=${dealType}&status=${status}&boosting=${boosting}&loadNum=${loadNum}&chunkSize=${chunkSize}`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getDealOptionsInfoStatus(
    dealType,
    status,
    // boosting,
    loadNum,
    chunkSize,
  ) {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/items/deal-info/statusTap?dealType=${dealType}&status=${status}&loadNum=${loadNum}&chunkSize=${chunkSize}`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getDealOptionsInfoStatusWithSc(
    dealType,
    status,
    // boosting,
    loadNum,
    chunkSize,
    selectionCriteria,
  ) {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/items/deal-info/statusTap-sc?dealType=${dealType}&status=${status}&loadNum=${loadNum}&chunkSize=${chunkSize}&selectionCriteria=${selectionCriteria}`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getTotalLengthByDealType(dealType, status) {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/items/deal-info/total?dealType=${dealType}&status=${status}`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getTotalLengthByDealTypeWithSc(dealType, status, selectionCriteria) {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/items/deal-info/total-sc?dealType=${dealType}&status=${status}&selectionCriteria=${selectionCriteria}`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getApplyTotalLengthByDealType(dealType, status) {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/items/deal-info/total?dealType=${dealType}&status=${status}`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getAcceptedTotalLengthByDealType(dealType, status) {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/items/deal-info/total?dealType=${dealType}&status=${status}`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getAcceptedTotalLengthByDealTypeWithSc(
    dealType,
    status,
    selectionCriteria,
  ) {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/items/deal-info/total-sc?dealType=${dealType}&status=${status}&selectionCriteria=${selectionCriteria}`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async updateDealOptionsInfoStatus(
    dealInfoId,
    status,
    commissionRate,
    discountRate,
  ) {
    try {
      const option = {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          status,
          dealInfoId,
          commissionRate,
          discountRate,
        }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/items/deal-info/status`,
        option,
      )

      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async updateDealOptionsInfoBoostingStatus(
    dealInfoId,
    status,
    commissionRate,
    boosting,
    category,
  ) {
    try {
      const option = {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          status,
          dealInfoId,
          commissionRate,
          boosting: boosting.toString(),
          category,
        }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/items/deal-info/boosting-status`,
        option,
      )

      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async updateDealExtensionApproval() {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/items/update-deal-extension-approval`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async updateDealStatus({
    dealType,
    itemId,
    wasTeamPurchasePrice,
    wasOptionsInfo,
    status,
    commissionRate,
    discountRate,
    mdManagedBrandName,
  }) {
    try {
      const option = {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          dealType,
          itemId,
          wasTeamPurchasePrice,
          wasOptionsInfo,
          status,
          commissionRate,
          discountRate,
          mdManagedBrandName,
        }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/items/update-deal-status`,
        option,
      )

      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async deleteDealStatus(dealType, itemId) {
    try {
      const options = {
        method: 'GET',
      }
      console.log('api started')
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/items/delete-deal-status?dealType=${dealType}&itemId=${itemId}`,
        options,
      )

      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async appendMinimumDiscountRateAndWasPrice(
    itemIds, // list of itemIds
  ) {
    try {
      const option = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          itemIds,
        }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/items/append-minimum-discount-rate-and-was-price`,
        option,
      )

      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async registerBankAccountInfoV2(bankAccountInfo) {
    try {
      const option = {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          bankAccountInfo,
        }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/bank-account-info-v2`,
        option,
      )

      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async updateBankAccountInfo(bankAccountInfo) {
    try {
      const option = {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          bankAccountInfo,
        }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/bank-account-info-approved`,
        option,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async registerBusinessRegistrationNumber(businessRegistrationNumber) {
    try {
      const option = {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          businessRegistrationNumber,
        }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/business-registration-number`,
        option,
      )

      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async rejectRegistrationInfoByKey(key) {
    try {
      const option = {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          key,
        }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/business-registration-info/reject`,
        option,
      )

      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getTotalItemLength() {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/items/total-item-length`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getCurrentUnitOptionBiddingData({ status }) {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/bidding/current-unit-option-bidding-data?status=${status}`,
        options,
      )
      const data = await res?.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getCurrentGeneralOptionBiddingData({ status }) {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/bidding/current-general-option-bidding-data?status=${status}`,
        options,
      )
      const data = await res?.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async loadItemQualityStats() {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/items/quality-stats`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async loadTotalItemQualityStats(itemId) {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/items/total-quality-stats?itemId=${itemId}`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async loadItemQualityManagement(page, limit) {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/items/quality-management?page=${page}&limit=${limit}`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async loadItemQualityManagementCount() {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/items/quality-management-count`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getRecentLowReview(itemId) {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/items/recent-low-score-review?itemId=${itemId}`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getRecentRefundsByItem(itemId) {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/items/recent-item-refund?itemId=${itemId}`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getRecentQuestionsByItem(itemId) {
    try {
      const options = {
        method: 'GET',
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/items/recent-item-question?itemId=${itemId}`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async modifySellerDetailInfo(
    sellerDisplayName,
    representorName,
    phoneNumber,
    contactNumber,
    email,
    description,
    defaultShippingCompanyId,
    address,
    countryCode,
  ) {
    try {
      const options = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          sellerDisplayName,
          representorName,
          phoneNumber,
          contactNumber,
          email,
          description,
          defaultShippingCompanyId,
          address,
          countryCode,
        }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/modify-seller-info`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getRefundCreditAmount({ adCpmSetId }) {
    try {
      const options = { method: 'GET' }
      const res = await fetchWrapper(
        `${configs.backendUrl}/ad-cpm/refund-credit-assumption?adCpmSetId=${adCpmSetId}`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async resetBusinessRegistrationNumber(sellerId) {
    try {
      const option = {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          sellerId,
        }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/reset-business-registration-number`,
        option,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async resetBusinessRegistrationNumberImage(sellerId) {
    try {
      const option = {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          sellerId,
        }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/reset-business-registration-number-image`,
        option,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async recognizeTextFromImage(businessImage) {
    try {
      const option = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          businessImage,
        }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/naver-ocr-business-registration-image`,
        option,
      )

      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async validateBusinessRegistrationNumber(
    sellerId,
    businessNumber,
    repName,
    openDate,
  ) {
    try {
      const option = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          sellerId,
          businessNumber,
          repName,
          openDate,
        }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/validate-business-registration-number`,
        option,
      )

      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async updateSellerBusinessRegistrationImageInfo(
    sellerId,
    bizRegisteredData,
    isValid,
    businessRegistrationNumberImageValidityReason,
  ) {
    try {
      const option = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          sellerId,
          bizRegisteredData,
          isValid,
          businessRegistrationNumberImageValidityReason,
        }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/update-business-registration-image-info`,
        option,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async updateSellerBusinessNumberValidity(sellerId) {
    try {
      const option = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          sellerId,
        }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/update-business-number-validity`,
        option,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async requestAccountHolderInfo(bankCode, bankAccountNumber) {
    try {
      const option = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          bankCode,
          bankAccountNumber,
        }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/request-account-holder-info`,
        option,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getCpmAdUsageLogInfo(page, pageSize, startDate, endDate) {
    try {
      const options = { method: 'GET' }
      const res = await fetchWrapper(
        `${configs.backendUrl}/ad-cpm/ad-cpm-credit-usage-log?page=${page}&size=${pageSize}&startDate=${startDate}&endDate=${endDate}`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async registerMailOrderSalesRegistrationNumber(
    mailOrderSalesRegistrationNumber,
  ) {
    try {
      const option = {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          mailOrderSalesRegistrationNumber,
        }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/mail-order-sales-registration-number`,
        option,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async resetMailOrderSalesRegistrationNumber(sellerId) {
    try {
      const option = {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ sellerId }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/reset-mail-order-sales-registration-number`,
        option,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async validateMailOrderSalesRegistrationNumber(businessNumber) {
    try {
      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      }

      const queryParams = new URLSearchParams({
        businessNumber,
      })

      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/validate-mail-order-sales-registration-number?${queryParams}`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
      return {
        success: false,
        error: err.message,
      }
    }
  }

  async checkDuplicateBusinessRegistrationNumber(businessNumber) {
    try {
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ businessNumber }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/check-duplicate-business-registration-number`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async updateSellerMailOrderSalesNumberValidity(sellerId, mailOrderSalesData) {
    try {
      const option = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          sellerId,
          mailOrderSalesData,
        }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/update-mail-order-sales-number-validity`,
        option,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async updateBusinessRegistrationInquiry(updateInquiryData) {
    try {
      const formData = new FormData()

      // 일반 데이터 추가
      formData.append('sellerName', updateInquiryData.sellerName)
      formData.append('sellerDisplayName', updateInquiryData.sellerDisplayName)
      formData.append('phoneNumber', updateInquiryData.phoneNumber)
      formData.append('type', updateInquiryData.type)
      formData.append('title', updateInquiryData.title)
      formData.append('content', updateInquiryData.content)

      // 파일 데이터 추가
      updateInquiryData.files.forEach((data, index) => {
        formData.append(
          'files',
          dataUriToBlob(data.sourceUri),
          `file_${index}.${data.sourceUri.split(';')[0].split('/')[1]}`,
        )
      })

      const option = {
        method: 'POST',
        body: formData,
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/update-business-registration-inquiry`,
        option,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getBusinessRegistrationInquiryHistory() {
    try {
      const option = { method: 'GET' }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/get-business-registration-inquiry-history`,
        option,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async sendVerificationCode(phoneNumber) {
    try {
      const option = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ phoneNumber }),
      }
      const res = await fetchWrapper(
        `${configs.backendUrl}/sellers/verification-code`,
        option,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }
}

export default new BackendApis()
