import React, { memo, useMemo } from 'react'
import { Bar } from 'react-chartjs-2'
import { Spin } from 'antd'

const CpmAdAnalyticsGraph = memo(
  ({
    data,
    loading,
    selectedMetrics = ['impressions', 'spentBudget', 'revenue', 'roas'],
    isUnderMaintenance = false,
    startDate,
    endDate,
  }) => {
    const processedData = useMemo(() => {
      if (!data || !Array.isArray(data)) return []

      const flatData = data.flat().filter(Boolean)

      // 일별로만 그룹화
      const dailyData = flatData.reduce((acc, curr) => {
        // 선택된 날짜 범위에 속하는 데이터만 처리
        const currDate = curr.date
        if (
          startDate &&
          endDate &&
          (currDate < startDate || currDate > endDate)
        ) {
          return acc
        }

        const dateKey = curr.date
        if (!acc[dateKey]) {
          acc[dateKey] = {
            date: dateKey,
            impressions: 0,
            spentBudget: 0,
            revenue: curr.revenue || 0,
          }
        }

        acc[dateKey].impressions += curr.impressions || 0
        acc[dateKey].spentBudget += curr.spentBudget || 0
        acc[dateKey].revenue += curr.revenue || 0

        return acc
      }, {})

      return Object.values(dailyData)
        .sort((a, b) => new Date(a.date) - new Date(b.date))
        .map((item) => ({
          ...item,
          roas:
            item.spentBudget > 0 ? (item.revenue / item.spentBudget) * 100 : 0,
        }))
    }, [data, startDate, endDate])

    if (isUnderMaintenance) {
      return (
        <div className='flex justify-center items-center h-48 text-gray-600 font-medium'>
          <p className='text-center'>
            🔧 시스템 점검 중입니다.
            <br />
            성과 분석 데이터는 상세 데이터 탭에서 확인하실 수 있습니다.
            <br />
            최대한 빠른 시일 내에 서비스를 정상화하도록 하겠습니다.
          </p>
        </div>
      )
    }

    if (loading) {
      return (
        <div className='flex justify-center items-center h-48'>
          <Spin tip='데이터를 불러오는 중...' />
        </div>
      )
    }

    if (!processedData.length) {
      return (
        <div className='flex justify-center items-center h-48'>
          데이터가 없습니다.
        </div>
      )
    }

    const metricsConfig = {
      impressions: {
        label: '노출 횟수',
        backgroundColor: '#6e93f1',
        yAxisID: 'y2',
        formatter: (value) => `${value.toLocaleString()}회`,
      },
      spentBudget: {
        label: '소진액',
        backgroundColor: '#ea5e1c',
        yAxisID: 'y',
        formatter: (value) => `₩${value.toLocaleString()}`,
      },
      revenue: {
        label: '매출',
        backgroundColor: '#29a652',
        yAxisID: 'y',
        formatter: (value) => `₩${value.toLocaleString()}`,
      },
      roas: {
        label: 'ROAS',
        backgroundColor: '#9061F9',
        yAxisID: 'y3',
        formatter: (value) => `${value.toFixed(0)}%`,
      },
    }

    const chartConfig = {
      data: {
        labels: processedData.map((item) => item.date),
        datasets: selectedMetrics.map((metric) => ({
          label: metricsConfig[metric].label,
          data: processedData.map((item) => item[metric]),
          backgroundColor: metricsConfig[metric].backgroundColor,
          borderRadius: 8,
          maxBarThickness: 50,
          yAxisID: metricsConfig[metric].yAxisID,
        })),
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        interaction: {
          mode: 'index',
          intersect: false,
        },
        plugins: {
          legend: {
            display: true,
            position: 'top',
          },
          tooltip: {
            backgroundColor: '#303030',
            padding: 12,
            titleFont: {
              size: 14,
              weight: 'bold',
            },
            bodyFont: {
              size: 13,
            },
            callbacks: {
              label: (context) => {
                const metric = selectedMetrics[context.datasetIndex]
                const value = context.raw
                return `${context.dataset.label}: ${metricsConfig[
                  metric
                ].formatter(value)}`
              },
            },
          },
        },
        scales: {
          x: {
            grid: {
              display: false,
            },
            ticks: {
              font: {
                size: 12,
              },
            },
          },
          y: {
            type: 'linear',
            position: 'left',
            beginAtZero: true,
            display: selectedMetrics.some((m) =>
              ['revenue', 'spentBudget'].includes(m),
            ),
            grid: {
              color: '#E5E7EB',
            },
            ticks: {
              font: {
                size: 12,
              },
              callback: (value) => `₩${value.toLocaleString()}`,
              maxTicksLimit: 10,
              autoSkip: true,
            },
          },
          y2: {
            type: 'linear',
            position: 'right',
            beginAtZero: true,
            display: selectedMetrics.includes('impressions'),
            grid: {
              display: false,
            },
            ticks: {
              font: {
                size: 12,
              },
              callback: (value) => `${value.toLocaleString()}회`,
              maxTicksLimit: 10,
              autoSkip: true,
            },
          },
          y3: {
            type: 'linear',
            position: 'right',
            beginAtZero: true,
            display: selectedMetrics.includes('roas'),
            grid: {
              display: false,
            },
            ticks: {
              font: {
                size: 12,
              },
              callback: (value) => `${value.toFixed(0)}%`,
              maxTicksLimit: 10,
              autoSkip: true,
            },
          },
        },
      },
    }

    return (
      <div className='h-[400px]'>
        <Bar data={chartConfig.data} options={chartConfig.options} />
      </div>
    )
  },
)

export default CpmAdAnalyticsGraph
