import SubTitle from '../components/atoms/subTitle'
import Page from '../components/atoms/page'
import { FcAdvertising } from 'react-icons/fc'
import Button from '../components/atoms/button'

const MdContactInfoScreen = () => {
  return (
    <div className='w-[85vw] pl-10 pr-20 py-10 mt-10 '>
      <div className='flex flex-row items-center flex-1 mb-5'>
        <FcAdvertising className='w-8 h-8 mr-2' />
        <div className='text-2xl font-bold select-none'>
          상품/프로모션 협력 제안 방법 안내
        </div>
      </div>
      <div className='p-10 mb-10 bg-white shadow-md rounded-xl min-w-[1000px] w-1/2'>
        <SubTitle className='mb-7'>상품/프로모션 협력 제안서</SubTitle>
        <div className='mb-2'>
          카테고리 담당자와 직접 컨택하여 프로모션 참여를 통해 매출 증대를
          원하시는 파트너 분들을 언제나 환영합니다!
        </div>
        <div>
          <span className='font-bold'>제안서 엑셀 파일 양식</span>에 맞춰
          작성하신 후에 대표 카테고리 이메일로 제안해 주세요!
        </div>
        <br />
        <div>
          * 제안서 엑셀 파일 양식 다운로드 페이지 -{' '}
          <a
            href='https://placid-hoverfly-3d1.notion.site/1960b952423c80f2849dee1fda42c5f0?pvs=4'
            target='_blank'
            rel='noreferrer'
            className='text-blue-500 underline'
          >
            이동하기
          </a>
        </div>

        <SubTitle className='mt-10 mb-7'>올웨이즈 대표 프로모션 안내</SubTitle>
        {/* 노션 문서 링크  */}
        <div className='mb-2'>
          올웨이즈 대표 프로모션의 종류, 진행 조건, 노출 영역 등의 정보는 안내
          문서를 통해 확인할 수 있습니다. -{' '}
          <a
            href='https://placid-hoverfly-3d1.notion.site/1960b952423c80f2849dee1fda42c5f0?pvs=4'
            target='_blank'
            rel='noreferrer'
            className='text-blue-500 underline'
          >
            안내 문서 확인하기
          </a>
        </div>

        <SubTitle className='mt-10 mb-7'>카테고리별 담당 이메일</SubTitle>
        <table className='w-full border-collapse mb-8'>
          <thead>
            <tr className='bg-gray-100'>
              <th className='border p-3 text-center'>카테고리</th>
              <th className='border p-3 text-center'>대표 이메일</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className='border p-3'>식품 & 생활</td>
              <td className='border p-3'>fs@ilevit.com</td>
            </tr>
            <tr>
              <td className='border p-3'>패션 & 뷰티</td>
              <td className='border p-3'>fb@ilevit.com</td>
            </tr>
          </tbody>
        </table>

        <SubTitle className='mt-10 mb-7'>이메일 작성 양식</SubTitle>
        <div className='bg-gray-50 p-4 rounded-lg'>
          <div className='mb-3'>
            작성하신 <span className='font-bold'>제안서 엑셀 파일</span>과 함께
            다음의 양식을 지켜 이메일을 보내주세요.
          </div>
          <ol className='list-decimal pl-5 space-y-2'>
            <li>파트너명: (올웨이즈 등록 사업자명과 동일)</li>
            <li>올웨이즈 파트너센터 로그인 ID:</li>
            <li>담당자명:</li>
            <li>담당자 연락처:</li>
            <li>담당자 이메일:</li>
            <li>주요 상품: 예시) 생수, 간식, 만두</li>
            <li>문의 사항:</li>
          </ol>
        </div>

        <SubTitle className='mt-10 mb-7'>유의사항</SubTitle>
        <div className='mb-2'>
          1. 올웨이즈 카테고리 MD와 직접 컨택, 프로모션 등의 운영 목적 외 기타
          문의는 답변이 어려울 수 있습니다.
        </div>
        <div className='mb-2'>
          2. 기재하신 정보와 일치하지 않거나, 협의 후 프로모션 조건이 맞지 않는
          경우에 프로모션 참여가 어려울 수 있습니다.
        </div>
        <div className='mb-2'>
          3. 첨부한 양식은 프로모션용 파일로 어드민에 등록된 정보와 동일하게
          작성하셔야 합니다.
        </div>
        <div>
          4. 많은 관심으로 인해 회신까지 시간이 다소 소요되는 점
          양해부탁드립니다. 담당 부서에서 최대한 빠르게 순차 처리 중이니 조금만
          기다려 주시길 바랍니다.
        </div>
      </div>
    </div>
  )
}

export default MdContactInfoScreen
