import React, { useState, useEffect, useMemo, useRef } from 'react'
import Button from '../atoms/button'
import { GrClose as CloseIcon } from 'react-icons/gr'
import backendApis from '../../utils/backendApis'
import 'react-credit-cards/es/styles-compiled.css'
import Pagination from './pagination'
import { FcAbout } from 'react-icons/fc'
import commaNumber from 'comma-number'
import Badge from '../ADS/atoms/badge'

const AdCreditCancelModal = ({ showModal, setShowModal, setBasicInfo }) => {
  const limit = 10
  const [page, setPage] = useState(1)
  const [logData, setLogData] = useState([])
  const [logsCount, setLogsCount] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const isCancelLoading = useRef(false)

  const typeTranslator = (type) => {
    if (type === 'buy-ad-credit') {
      return <Badge text='광고 크레딧 충전' />
    }
    if (type === 'bonus-credit') {
      return <Badge text='무료 크레딧 지급' color='green' />
    }
    if (type === 'ticket-credit') {
      return <Badge text='크레딧 티켓 사용' color='yellow' />
    }

    return null
  }

  const getAdCreditInfo = async (limit, page) => {
    const resultAdCredit = await backendApis.getAdCreditInfo()
    if (resultAdCredit?.status === 2000) {
      setBasicInfo(resultAdCredit?.data)
    }
    const count = await backendApis.getAdCreditLogCount()
    if (count?.status === 200) {
      setLogsCount(count?.data)
    }
    const result = await backendApis.getAdCreditLogInfo(limit, page)
    if (result?.status === 200) {
      const creditLog = result?.data
        .filter((data) => data.type === 'buy-ad-credit')
        .map((e) => {
          const cardName =
            `${e?.paymentInfo?.paymentMethodInfo?.cardName || '-'} / ` +
            `${e?.paymentInfo?.paymentMethodInfo?.cardFirstNumbers || '-'}`
          const creditAmount = commaNumber(e?.paymentInfo?.creditAmount || 0)
          const cancelAmount = commaNumber(
            e?.cancelInfo?.reduce((acc, cur) => acc + cur?.cancelAmount, 0) ||
              0,
          )
          let availableCreditAmount = 0
          if (e?.type === 'buy-ad-credit') {
            availableCreditAmount = commaNumber(e?.availableCreditAmount || 0)
          }
          return {
            ...e,
            cardName,
            type: typeTranslator(e?.type) ?? '-',
            creditAmount: `${creditAmount}원`,
            cancelAmount: `${cancelAmount}원`,
            availableCreditAmount: `${availableCreditAmount}원`,
            paymentStartTime: new Date(e?.paymentStartTime).toLocaleString(),
          }
        })
      setLogData(creditLog)
    }
  }

  useEffect(() => {
    if (isLoading) return
    setIsLoading(true)
    getAdCreditInfo(limit, page)
    setIsLoading(false)
  }, [page])

  const columns = useMemo(
    () => [
      { Header: '결제수단', accessor: 'cardName' },
      { Header: '유형', accessor: 'type' },
      { Header: '충전금액', accessor: 'creditAmount' },
      { Header: '환불가능금액', accessor: 'availableCreditAmount' },
      { Header: '환불금액', accessor: 'cancelAmount' },
      { Header: '결제일시', accessor: 'paymentStartTime' },
      {
        Header: '취소 신청',
        Cell: ({ row }) =>
          row?.original?.availableCreditAmount === '0원' ? (
            '취소 불가'
          ) : (
            <button
              type='button'
              className='inline-block px-4 py-2 mr-5 border rounded-lg w-fit hover:bg-gray-100'
              onClick={async () => {
                if (isLoading) return
                if (isCancelLoading.current) return

                if (
                  window.confirm(
                    `해당 결제건을 취소하시겠습니까? 환불예정액:${commaNumber(
                      row?.original?.availableCreditAmount,
                    )}`,
                  )
                ) {
                  isCancelLoading.current = true
                  setIsLoading(true)

                  try {
                    const result = await backendApis.cancelAdCredit(
                      row?.original?._id,
                    )
                    if (result?.status === 2000) {
                      alert(
                        `${commaNumber(
                          row?.original?.availableCreditAmount,
                        )} 취소되었습니다.`,
                      )
                    }

                    alert(`${result?.msg ?? ''}`)

                    await getAdCreditInfo(limit, page)
                    setIsLoading(false)
                    isCancelLoading.current = false
                  } catch (error) {
                    alert(error?.message ?? '취소 실패')
                  } finally {
                    setIsLoading(false)
                    isCancelLoading.current = false
                  }
                }
              }}
            >
              취소
            </button>
          ),
      },
    ],
    [],
  )

  return (
    <>
      {showModal && (
        <>
          <div className='max-h-[800px] fixed inset-0 z-50 flex flex-col w-full max-w-5xl mx-auto my-auto bg-white border-0 rounded-lg shadow-lg outline-none h-fit'>
            {/* header */}
            <div className='flex items-start justify-between p-5 border-b border-solid border-slate-200'>
              <div className='text-xl font-semibold text-black'>
                광고 크레딧 취소/환불
              </div>
              <Button
                appearance='transparent'
                className='py-3 px-3 my-[-6px] rounded-md hover:bg-gray-100'
                onClick={() => setShowModal(false)}
              >
                <CloseIcon className='w-4 h-4' />
              </Button>
            </div>

            {/* body */}
            <div className='relative flex-auto p-10'>
              <div className='flex flex-row items-center justify-start flex-1 p-5 mb-4 bg-slate-100 rounded-xl'>
                <FcAbout className='mr-3 w-7 h-7' />
                <div className='flex flex-col items-start'>
                  &#39;환불가능금액&#39;은 광고소진액을 차감한 금액으로,
                  충전금액과 다를 수 있습니다.
                </div>
              </div>

              {/* 테이블 컨테이너 */}
              <div className='border rounded-xl'>
                <div className='relative'>
                  {/* 헤더 고정 */}
                  <div className='sticky top-0 z-10 bg-white border-b rounded-t-xl'>
                    <div className='flex py-3'>
                      {columns.map((column) => (
                        <div
                          key={column.accessor}
                          className='flex items-center justify-center flex-1'
                        >
                          {column.Header}
                        </div>
                      ))}
                    </div>
                  </div>

                  {/* 스크롤되는 테이블 본문 */}
                  <div className='overflow-y-auto h-[400px]'>
                    {logData.map((item) => (
                      <div
                        key={item._id}
                        className='flex py-3 border-b hover:bg-gray-50'
                      >
                        {columns.map((column) => (
                          <div
                            key={column.accessor}
                            className='flex items-center justify-center flex-1'
                          >
                            {column.Cell
                              ? column.Cell({ row: { original: item } })
                              : item[column.accessor]}
                          </div>
                        ))}
                      </div>
                    ))}
                  </div>
                </div>
              </div>

              {/* 페이지네이션 고정 위치 */}
              <div className='mt-4'>
                <Pagination
                  total={logsCount}
                  limit={limit}
                  page={page}
                  setPage={setPage}
                  size='md'
                />
              </div>
            </div>
          </div>
          <div className='fixed inset-0 z-40 bg-black opacity-25' />
        </>
      )}
    </>
  )
}
export default AdCreditCancelModal
