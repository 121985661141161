import React, { useEffect, useState, useMemo } from 'react'
import { observer } from 'mobx-react-lite'
import backendApis from '../../utils/backendApis'
import { Tooltip as BasicTooltip } from 'react-tippy'
import Tooltip from '../ADS/atoms/tooltip'
import Button from '../atoms/button'
import Format from '../../utils/format'
import SellerStore from '../../stores/SellerStore'
import adSettingByTab from '../../data/adSettingByTab'
import { GrClose as CloseIcon } from 'react-icons/gr'
import SellerQuest from '../../utils/sellerQuest'
import Table from '../ADS/molecules/table'

let isNLPRequestPending = false

const SearchAdApplyModal = observer(
  ({
    itemInfo,
    tab,
    status,
    showModal,
    setShowModal,
    callbackFunction = () => {},
  }) => {
    const [creditInfo, setCreditInfo] = useState({})
    const [recommendedKeywords, setRecommendedKeywords] = useState([])
    const [keywords, setKeywords] = useState([])
    const [dailyCostLimit, setDailyCostLimit] = useState()
    const [basicBiddingPrice, setBasicBiddingPrice] = useState()
    const [adInfo, setAdInfo] = useState([])
    const cleanUp = () => {
      setKeywords([])
      setRecommendedKeywords([])
      setDailyCostLimit()
      setAdInfo([])
    }
    const [showAlertModal, setShowAlertModal] = useState(false)

    const AlertModel = () => {
      return (
        <div
          className='relative z-50'
          aria-labelledby='alert-modal-title'
          role='dialog'
          aria-modal='true'
        >
          <div className='fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75' />
          <div className='fixed inset-0 z-10 overflow-y-auto'>
            <div className='flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0'>
              <div className='relative overflow-hidden text-left transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:w-full sm:max-w-lg'>
                <div className='px-4 pt-5 pb-4 bg-white sm:p-6 sm:pb-4'>
                  <div className='sm:flex sm:items-start'>
                    <div className='flex items-center justify-center flex-shrink-0 w-12 h-12 mx-auto bg-red-100 rounded-full sm:mx-0 sm:h-10 sm:w-10'>
                      <svg
                        className='w-6 h-6 text-red-600'
                        fill='none'
                        viewBox='0 0 24 24'
                        strokeWidth='1.5'
                        stroke='currentColor'
                        aria-hidden='true'
                      >
                        <path
                          strokeLinecap='round'
                          strokeLinejoin='round'
                          d='M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z'
                        />
                      </svg>
                    </div>
                    {/* Alert Title */}
                    <div className='mt-3 sm:ml-4 sm:mt-0 sm:text-left'>
                      <div className='flex flex-col justify-center h-10 '>
                        <div
                          className='text-xl font-semibold leading-6 text-gray-900'
                          id='alert-modal-title'
                        >
                          (주의) 클릭 입찰가 확인 요청
                        </div>
                      </div>
                      {/* Alert Body(Children) */}
                      <div className='pr-3 mt-2 text-gray-500 text-md'>
                        5,000원 이상의 클릭 입찰가가 설정되었어요. 이 클릭
                        입찰가로 광고를 신청하시겠어요?
                      </div>
                    </div>
                  </div>
                </div>
                <div className='justify-end px-4 py-3 bg-gray-50 sm:flex sm:px-6'>
                  <div className='flex-row'>
                    <div>
                      <Button
                        size='md'
                        appearance='outline'
                        onClick={() => {
                          setShowAlertModal(false)
                        }}
                      >
                        <div className='px-1 py-1 text-slate-500'>취소</div>
                      </Button>
                      <Button
                        className='ml-2'
                        size='md'
                        appearance='positive'
                        onClick={async () => {
                          if (adInfo?._id) {
                            await clickUpdateCpcAd()
                          } else {
                            await clickRegisterCpcAd()
                          }
                        }}
                      >
                        <div className='px-2 py-1'>확인</div>
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    }

    const wordSimAndAvgBiddingPriceChecker = async (itemTitle, keyword) => {
      const result = await backendApis.getWordNLPSimAndAvgBiddingPrice(
        itemTitle,
        keyword,
      )
      const nlpSimilarity = result?.data?.nlpSimilarity || 0
      const avgBiddingPrice = result?.data?.avgBiddingPrice || 90
      return { nlpSimilarity, avgBiddingPrice }
    }

    const columns = [
      { Header: '키워드', accessor: 'keyword' },
      {
        Header: (
          <>
            키워드 별 입찰가
            <Tooltip text='키워드 별 최소 입찰가는 90원이며, 입력란 우측의 추천 금액은 해당 키워드 입찰가의 평균(최근 7일) 입니다.' />
            <span className='text-sm font-normal text-gray-400'>
              {' '}
              (최소 입찰가 90원)
            </span>
          </>
        ),
        accessor: 'biddingPrice',
      },
    ]

    const updateBiddingPrice = (indexToUpdate, newBiddingPrice) => {
      const updatedKeywords = keywords.map((keyword, i) => {
        if (i === indexToUpdate) {
          return { ...keyword, biddingPrice: parseInt(newBiddingPrice, 10) }
        }
        return keyword
      })
      setKeywords(updatedKeywords)
    }

    const keywordData = useMemo(
      () =>
        keywords.map((item, idx) => {
          return {
            keyword: (
              <div className='text-left whitespace-normal'>
                <BasicTooltip
                  arrow='true'
                  theme='light'
                  title='상품과 관련도 높은 키워드는 파란색으로 표시되며, 관련도 낮은 키워드는 빨간색으로 표시됩니다. 빨간색으로 표시된 키워드에는 광고가 노출되지 않습니다.'
                  animation='fade'
                  position='bottom'
                >
                  <div
                    className={`inline-block mb-0.5 w-2 h-2 mr-2 rounded-lg ${
                      item?.nlpSimilarity < 0.1 ? 'bg-red-400' : 'bg-blue-400'
                    } `}
                  />
                  {item?.keyword}
                </BasicTooltip>
              </div>
            ),
            biddingPrice: (
              <div className='flex flex-row items-center justify-between flex-1 text-right'>
                <div>
                  <input
                    type='number'
                    max={1000000}
                    min={90}
                    step={10}
                    className='w-24 px-2 mr-2 border-gray-300 rounded-md focus:border-blue-500 focus:ring-blue-500 sm:text-sm'
                    placeholder='ex.800'
                    onChange={(e) => updateBiddingPrice(idx, e.target.value)}
                    value={item?.biddingPrice}
                  />
                  원
                  <div className='inline-block px-2 py-1 ml-4 text-sm text-gray-800 bg-gray-100 rounded'>
                    <span className='font-bold'>
                      {item?.avgBiddingPrice || 90}
                    </span>
                    원 추천
                  </div>
                </div>
                <button
                  type='button'
                  className='inline-block p-2 ml-4 border rounded-lg w-fit hover:bg-gray-100'
                  onClick={() => {
                    const newKeywords = [...keywords]
                    newKeywords.splice(idx, 1)
                    setKeywords(newKeywords)
                  }}
                >
                  삭제
                </button>
              </div>
            ),
          }
        }),
      [keywords],
    )

    const addKeyword = (keyword) => {
      if (SellerStore.isLoading) return
      SellerStore.setIsLoading(true)

      if (!keyword?.keyword) {
        alert('키워드를 입력해주세요.')
      } else if (
        keywords.filter((e) => e.keyword === keyword?.keyword).length > 0
      ) {
        alert('이미 추가한 키워드입니다.')
      } else if (keywords.length >= 30) {
        alert('광고 키워드는 최대 30개까지 설정하실 수 있습니다.')
      } else {
        setKeywords([...keywords, keyword])
      }

      SellerStore.setIsLoading(false)
    }

    useEffect(() => {
      const getAdCreditInfo = async () => {
        const result = await backendApis.getAdCreditInfo()
        if (result?.status === 2000) {
          setCreditInfo(result?.data)
        }
      }
      getAdCreditInfo()
    }, [])

    useEffect(() => {
      if (!showModal || SellerStore?.isLoading) return
      SellerStore.setIsLoading(true)
      const getRecommendedKeywords = async (itemTitle, smallCategoryId) => {
        const result = await backendApis.getRecommendedKeywords(
          itemTitle,
          smallCategoryId,
        )
        if (result?.status === 2000) {
          setRecommendedKeywords([...result?.data])
          if (status === 'candidate') {
            setKeywords([...result?.data])
          }
        } else {
          setRecommendedKeywords([])
        }
      }
      const getAdInfo = async (itemId, adType) => {
        const result = await backendApis.getItemAd(itemId, adType)
        if (result?.status === 200) {
          setBasicBiddingPrice(result?.data?.detailInfo?.basicBiddingPrice)
          setKeywords(result?.data?.detailInfo?.keywords)
          setDailyCostLimit(result?.data?.detailInfo?.dailyCostLimit)
          setAdInfo(result?.data)
        } else {
          setBasicBiddingPrice()
          setKeywords([])
          setDailyCostLimit()
          setAdInfo([])
        }
      }

      if (itemInfo?.itemTitle && itemInfo?.categoryInfo?.smallCategoryId) {
        getRecommendedKeywords(
          itemInfo?.itemTitle,
          parseInt(itemInfo?.categoryInfo?.smallCategoryId, 10),
        )
      }

      if (itemInfo?._id && status === 'complete') {
        getAdInfo(itemInfo?._id, adSettingByTab?.[tab]?.type)
      }

      SellerStore.setIsLoading(false)
    }, [showModal, itemInfo?._id, itemInfo?.itemTitle, itemInfo?.rankInfo])

    const clickRegisterCpcAd = async () => {
      setShowAlertModal(false)
      if (SellerStore.isLoading === true) return
      SellerStore.setIsLoading(true)

      if (keywords?.length === 0) {
        alert('상품이 노출될 키워드를 1개 이상 입력해주세요.')
        SellerStore.setIsLoading(false)
        return
      }

      if (
        dailyCostLimit !== undefined &&
        (dailyCostLimit === '' || dailyCostLimit < 100)
      ) {
        alert('일 광고 예산을 설정하려면 100원 이상으로 입력해주세요.')
        SellerStore.setIsLoading(false)
        return
      }

      const biddingPricCheck = keywords?.filter((i) => i.biddingPrice >= 90)
      if (
        keywords?.length > biddingPricCheck?.length ||
        biddingPricCheck?.length === 0
      ) {
        alert('키워드 입찰가는 90원 이상으로 입력해주세요.')
        SellerStore.setIsLoading(false)
        return
      }

      if (dailyCostLimit === undefined || dailyCostLimit >= 100) {
        const detailInfo = {
          keywords: keywords?.map((e) => {
            return {
              keyword: e?.keyword,
              nlpSimilarity: e?.nlpSimilarity,
              biddingPrice: e?.biddingPrice,
            }
          }),
          dailyCostLimit: parseInt(dailyCostLimit, 10) || undefined,
          basicBiddingPrice: parseInt(basicBiddingPrice, 10) || 90,
        }
        const result = await backendApis.registerItemAd(
          itemInfo,
          'cpcAd', // adType
          detailInfo,
        )
        setShowModal(false)
        SellerStore.setIsLoading(false)
        if (result?.status === 200) {
          alert(`${adSettingByTab?.[tab].name} 신청이 완료되었습니다.`)
          if (!SellerQuest.questChecker('adQuest', 'registerAdItem')) {
            await SellerQuest.questClearer('adQuest', 'registerAdItem')
          }
          callbackFunction()
          return
        }
      }
      alert('광고 신청에 실패했습니다. 새로고침 후 다시 시도해주세요.')
    }

    const clickUpdateCpcAd = async () => {
      setShowAlertModal(false)
      if (SellerStore.isLoading === true) return
      SellerStore.setIsLoading(true)

      if (keywords?.length === 0) {
        alert('상품이 노출될 키워드를 1개 이상 입력해주세요.')
        SellerStore.setIsLoading(false)
        return
      }

      if (
        dailyCostLimit !== undefined &&
        (dailyCostLimit === '' || dailyCostLimit < 100)
      ) {
        alert('일 광고 예산을 설정하려면 100원 이상으로 입력해주세요.')
        SellerStore.setIsLoading(false)
        return
      }

      const biddingPricCheck = keywords?.filter((i) => i.biddingPrice >= 90)
      if (
        keywords?.length > biddingPricCheck?.length ||
        biddingPricCheck?.length === 0
      ) {
        alert('키워드 입찰가는 90원 이상으로 입력해주세요.')
        SellerStore.setIsLoading(false)
        return
      }

      if (dailyCostLimit === undefined || dailyCostLimit >= 100) {
        const detailInfo = {
          keywords: keywords?.map((e) => {
            return {
              keyword: e?.keyword,
              nlpSimilarity: e?.nlpSimilarity,
              biddingPrice: e?.biddingPrice,
            }
          }),
          dailyCostLimit: parseInt(dailyCostLimit, 10) || undefined,
          basicBiddingPrice: parseInt(basicBiddingPrice, 10) || 90,
        }
        const result = await backendApis.updateItemAd(adInfo?._id, detailInfo)
        setShowModal(false)
        SellerStore.setIsLoading(false)
        if (result?.status === 200) {
          alert(`${adSettingByTab?.[tab].name} 신청이 완료되었습니다.`)
          if (!SellerQuest.questChecker('adQuest', 'registerAdItem')) {
            await SellerQuest.questClearer('adQuest', 'registerAdItem')
          }
          callbackFunction()
          return
        }
      }
      alert('광고 신청에 실패했습니다. 새로고침 후 다시 시도해주세요.')
    }

    return (
      <>
        {showModal ? (
          <>
            <div className='fixed inset-0 z-50 flex flex-col w-full max-w-3xl mx-auto my-auto bg-white border-0 rounded-lg shadow-lg outline-none h-[800px]'>
              {/* header - 고정 */}
              <div className='flex items-start justify-between p-5 border-b border-solid border-slate-200'>
                <h3 className='text-xl font-semibold text-black'>
                  {itemInfo?.itemTitle}
                </h3>
                <Button
                  appearance='transparent'
                  className='py-3 px-3 my-[-6px] rounded-md hover:bg-gray-100'
                  onClick={() => {
                    cleanUp()
                    setShowModal(false)
                    SellerStore.setIsLoading(false)
                  }}
                >
                  <CloseIcon className='w-4 h-4' />
                </Button>
              </div>

              {/* body - 스크롤 가능 */}
              <div className='flex-1 overflow-y-auto'>
                <div className='flex flex-col px-10 py-5'>
                  <div className='flex flex-col justify-center flex-1 mb-8 select-none items-between'>
                    <div className='flex flex-row items-center flex-1 mb-2 text-xl font-bold'>
                      1. 기본 입찰가 설정
                      <Tooltip text='파워 퍼포먼스 광고는 상품과 연관된 검색 결과 및 추천 매대(비검색 화면)에 노출될 수 있습니다. 미입력시 90원으로 설정됩니다.' />
                    </div>
                    <div className='mb-2'>
                      비검색 화면 광고 노출시 원하는 입찰가를 입력해주세요.
                    </div>
                    <div className='flex flex-row items-center justify-start flex-1'>
                      <input
                        type='number'
                        max={1000000}
                        min={90}
                        step={10}
                        className='w-24 px-2 mr-2 border-gray-300 rounded-md focus:border-blue-500 focus:ring-blue-500 sm:text-sm'
                        placeholder='ex.800'
                        onChange={(e) => setBasicBiddingPrice(e.target.value)}
                        value={basicBiddingPrice}
                      />
                      원
                    </div>
                  </div>

                  <div className='flex flex-col justify-center flex-1 mb-8'>
                    <div className='mb-2 text-xl font-bold'>
                      2. 광고 키워드 설정
                    </div>
                    <div className='mb-4'>
                      <div>
                        광고가 노출될 키워드를 입찰가와 함께 입력해주세요. (최대
                        30개)
                      </div>
                      <div className='text-sm text-gray-500'>
                        * 관련도가 낮으면 빨간색으로 표시되며, 광고가 노출되지
                        않습니다.
                        <br /> 미리 입력되어 있는 키워드는 추천 키워드입니다.
                      </div>
                    </div>

                    <div className='flex flex-row flex-1'>
                      <form
                        onSubmit={async (e) => {
                          if (isNLPRequestPending) {
                            alert('이전 요청이 아직 완료되지 않았습니다.')
                            return
                          }

                          e.preventDefault()
                          const keyword = Format.Title(
                            e.target[0].value.normalize('NFC'),
                          )

                          if (!keyword) {
                            alert('키워드를 입력해주세요.')
                            SellerStore.setIsLoading(false)
                            return
                          }

                          try {
                            isNLPRequestPending = true
                            const { nlpSimilarity, avgBiddingPrice } =
                              await wordSimAndAvgBiddingPriceChecker(
                                itemInfo?.itemTitle,
                                keyword,
                              )
                            addKeyword({
                              keyword,
                              nlpSimilarity,
                              avgBiddingPrice,
                            })
                            e.target[0].value = ''
                          } catch (err) {
                            alert('알 수 없는 에러가 발생했어요.')
                          } finally {
                            isNLPRequestPending = false
                          }
                        }}
                      >
                        <div className='flex flex-row flex-1'>
                          <input
                            type='text'
                            disabled={keywords?.length > 30}
                            className='block w-full pl-2 pr-3 mr-3 border-gray-300 rounded-md focus:border-blue-500 focus:ring-blue-500'
                            placeholder='키워드를 입력해주세요'
                          />
                          <Button
                            appearance='neutral'
                            disabled={keywords?.length >= 30}
                            className='p-2 text-white whitespace-nowrap bg-slate-500 rounded-xl'
                            type='submit'
                          >
                            추가
                          </Button>
                        </div>
                      </form>
                    </div>

                    {recommendedKeywords?.filter(
                      (e) =>
                        !keywords?.map((e) => e.keyword)?.includes(e.keyword),
                    ).length > 0 && (
                      <div className='flex-col items-start flex-1 p-5 mt-3 bg-slate-100 rounded-xl'>
                        <div className='text-gray-500 '>추천 키워드</div>
                        {recommendedKeywords
                          ?.filter(
                            (e) =>
                              !keywords
                                ?.map((e) => e.keyword)
                                ?.includes(e.keyword),
                          )
                          .map((item) => {
                            return (
                              <button
                                type='button'
                                className='px-2 py-1 my-1 mr-2 font-semibold text-white rounded-lg hover:bg-sub-400 bg-slate-400 whitespace-nowrap'
                                onClick={() => addKeyword(item)}
                              >
                                + {item?.keyword}
                              </button>
                            )
                          })}
                      </div>
                    )}

                    <div className='mt-2 px-0.5'>
                      <Table columns={columns} data={keywordData} />
                    </div>
                  </div>

                  <div className='flex flex-col justify-center flex-1 select-none items-between'>
                    <div className='flex flex-row items-center flex-1 mb-2 text-xl font-bold'>
                      3. 일 광고 예산 설정
                      <Tooltip text='상품마다 일 광고 예산을 설정할 수 있으며, 광고 소진액이 이를 넘으면 광고 송출이 일시중지됩니다. 일시중지 반영까지 시간이 소요되어, 예산을 일부 초과해 소진될 수 있습니다. (일시중지된 광고는 다음날부터 다시 송출됩니다.)' />
                      <div className='text-sm font-bold text-blue-500'>
                        * 예산을 일부 초과하여 소진될 수 있습니다.
                      </div>
                    </div>
                    <div className='flex flex-row items-center justify-start flex-1'>
                      <label
                        htmlFor='limitless'
                        className='flex flex-row items-center justify-center mr-3'
                      >
                        <input
                          type='radio'
                          id='limitless'
                          checked={dailyCostLimit === undefined}
                          onChange={() => setDailyCostLimit()}
                        />
                        <span className='ml-2 mr-5'>제한 없음</span>
                      </label>
                      <label
                        htmlFor='limit'
                        className='flex flex-row items-center justify-center mr-10 '
                      >
                        <input
                          type='radio'
                          id='limit'
                          checked={dailyCostLimit !== undefined}
                          onChange={() => setDailyCostLimit('')}
                        />
                        <div className='ml-2'>직접 입력</div>
                        <input
                          type='number'
                          min={100}
                          step={10}
                          className='w-24 px-2 ml-4 border-gray-300 rounded-md focus:border-blue-500 focus:ring-blue-500 sm:text-sm'
                          placeholder='ex.10,000'
                          onFocus={() => setDailyCostLimit('')}
                          onChange={(e) => setDailyCostLimit(e.target.value)}
                          value={dailyCostLimit !== undefined && dailyCostLimit}
                        />
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              {/* footer - 고정 */}
              <div className='flex items-center justify-end p-5 bg-gray-100 border-t border-solid rounded-b border-slate-200'>
                {adInfo?._id ? (
                  <>
                    <Button
                      className='mx-4 rounded-md hover:bg-gray-200'
                      appearance='teritiary'
                      size='lg'
                      type='button'
                      onClick={async () => {
                        if (SellerStore.isLoading) return
                        SellerStore.setIsLoading(true)

                        const result = await backendApis.deleteItemAd(
                          adInfo?._id,
                          adInfo?.adType,
                          itemInfo?._id,
                        )
                        if (result?.status === 200) {
                          alert('광고가 취소되었습니다.')
                        }
                        cleanUp()
                        setShowModal(false)
                        SellerStore.setIsLoading(false)
                        callbackFunction()
                      }}
                    >
                      광고 삭제
                    </Button>
                    <Button
                      appearance='positiveSub'
                      size='lg'
                      type='button'
                      onClick={async () => {
                        const biddingPricCheckOverMax = keywords?.filter(
                          (i) => i.biddingPrice >= 5000,
                        )
                        if (
                          biddingPricCheckOverMax?.length > 0 ||
                          basicBiddingPrice >= 5000
                        ) {
                          setShowAlertModal(true)
                          SellerStore.setIsLoading(false)
                          return
                        }
                        await clickUpdateCpcAd()
                      }}
                    >
                      {adSettingByTab?.[tab].name} 수정
                    </Button>
                  </>
                ) : (
                  <Button
                    appearance={
                      (creditInfo?.adCreditInfo?.paidCredit || 0) +
                        (creditInfo?.adCreditInfo?.supportCredit || 0) >
                      0
                        ? 'positive'
                        : 'disabled'
                    }
                    disabled={
                      (creditInfo?.adCreditInfo?.paidCredit || 0) +
                        (creditInfo?.adCreditInfo?.supportCredit || 0) <=
                      0
                    }
                    size='lg'
                    type='button'
                    onClick={async () => {
                      const biddingPricCheckOverMax = keywords?.filter(
                        (i) => i.biddingPrice >= 5000,
                      )
                      if (
                        biddingPricCheckOverMax?.length > 0 ||
                        basicBiddingPrice >= 5000
                      ) {
                        setShowAlertModal(true)
                        SellerStore.setIsLoading(false)
                        return
                      }
                      await clickRegisterCpcAd()
                    }}
                  >
                    {(creditInfo?.adCreditInfo?.paidCredit || 0) +
                      (creditInfo?.adCreditInfo?.supportCredit || 0) >
                    0
                      ? '광고 신청'
                      : '광고 신청 불가 (크레딧 부족)'}
                  </Button>
                )}
              </div>
            </div>
            <div className='fixed inset-0 z-40 bg-black opacity-25' />
            {showAlertModal && <AlertModel />}
          </>
        ) : null}
      </>
    )
  },
)
export default SearchAdApplyModal
